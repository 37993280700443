/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * South Street Private API
 * OpenAPI spec version: 0.0.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import { customAxios } from './custom-axios';
export type JoinTradeRequestChat500AllOfTwoErrorCode = typeof JoinTradeRequestChat500AllOfTwoErrorCode[keyof typeof JoinTradeRequestChat500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinTradeRequestChat500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type JoinTradeRequestChat500AllOfTwoError = {
  status: number;
  code: JoinTradeRequestChat500AllOfTwoErrorCode;
};

export type JoinTradeRequestChat500AllOfTwo = {
  statusCode: number;
  error: JoinTradeRequestChat500AllOfTwoError;
};

export type JoinTradeRequestChat500AllOf = {
  error: ApiError;
};

export type JoinTradeRequestChat500 = ApiErrorResponseDto & JoinTradeRequestChat500AllOf & JoinTradeRequestChat500AllOfTwo;

export type JoinTradeRequestChat404AllOfTwoErrorCode = typeof JoinTradeRequestChat404AllOfTwoErrorCode[keyof typeof JoinTradeRequestChat404AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JoinTradeRequestChat404AllOfTwoErrorCode = {
  null: 'null',
} as const;

export type JoinTradeRequestChat404AllOfTwoError = {
  status: number;
  code: JoinTradeRequestChat404AllOfTwoErrorCode;
};

export type JoinTradeRequestChat404AllOfTwo = {
  statusCode: number;
  error: JoinTradeRequestChat404AllOfTwoError;
};

export type JoinTradeRequestChat404AllOf = {
  error: NoOpenTradeRequestChatFoundError;
};

export type JoinTradeRequestChat404 = ApiErrorResponseDto & JoinTradeRequestChat404AllOf & JoinTradeRequestChat404AllOfTwo;

export type JoinTradeRequestChat200 = {
  data: Function;
};

export type GetStaffUserTradeHistory500AllOfTwoErrorCode = typeof GetStaffUserTradeHistory500AllOfTwoErrorCode[keyof typeof GetStaffUserTradeHistory500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetStaffUserTradeHistory500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetStaffUserTradeHistory500AllOfTwoError = {
  status: number;
  code: GetStaffUserTradeHistory500AllOfTwoErrorCode;
};

export type GetStaffUserTradeHistory500AllOfTwo = {
  statusCode: number;
  error: GetStaffUserTradeHistory500AllOfTwoError;
};

export type GetStaffUserTradeHistory500AllOf = {
  error: ApiError;
};

export type GetStaffUserTradeHistory500 = ApiErrorResponseDto & GetStaffUserTradeHistory500AllOf & GetStaffUserTradeHistory500AllOfTwo;

export type GetStaffUserTradeHistoryParams = { nextCursor?: string; limit?: number };

export type DissociateEndUser500AllOfTwoErrorCode = typeof DissociateEndUser500AllOfTwoErrorCode[keyof typeof DissociateEndUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DissociateEndUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type DissociateEndUser500AllOfTwoError = {
  status: number;
  code: DissociateEndUser500AllOfTwoErrorCode;
};

export type DissociateEndUser500AllOfTwo = {
  statusCode: number;
  error: DissociateEndUser500AllOfTwoError;
};

export type DissociateEndUser500AllOf = {
  error: ApiError;
};

export type DissociateEndUser500 = ApiErrorResponseDto & DissociateEndUser500AllOf & DissociateEndUser500AllOfTwo;

export type DissociateEndUser200 = {
  data: Function;
};

export type AttachAnalyticFirm500AllOfTwoErrorCode = typeof AttachAnalyticFirm500AllOfTwoErrorCode[keyof typeof AttachAnalyticFirm500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttachAnalyticFirm500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type AttachAnalyticFirm500AllOfTwoError = {
  status: number;
  code: AttachAnalyticFirm500AllOfTwoErrorCode;
};

export type AttachAnalyticFirm500AllOfTwo = {
  statusCode: number;
  error: AttachAnalyticFirm500AllOfTwoError;
};

export type AttachAnalyticFirm500AllOf = {
  error: ApiError;
};

export type AttachAnalyticFirm500 = ApiErrorResponseDto & AttachAnalyticFirm500AllOf & AttachAnalyticFirm500AllOfTwo;

export type AttachAnalyticFirm200 = {
  data: AnalyticFirmListResponseDto;
};

export type UpdateAnalyticFirm500AllOfTwoErrorCode = typeof UpdateAnalyticFirm500AllOfTwoErrorCode[keyof typeof UpdateAnalyticFirm500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateAnalyticFirm500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type UpdateAnalyticFirm500AllOfTwoError = {
  status: number;
  code: UpdateAnalyticFirm500AllOfTwoErrorCode;
};

export type UpdateAnalyticFirm500AllOfTwo = {
  statusCode: number;
  error: UpdateAnalyticFirm500AllOfTwoError;
};

export type UpdateAnalyticFirm500AllOf = {
  error: ApiError;
};

export type UpdateAnalyticFirm500 = ApiErrorResponseDto & UpdateAnalyticFirm500AllOf & UpdateAnalyticFirm500AllOfTwo;

export type UpdateAnalyticFirm200 = {
  data: AnalyticFirmListResponseDto;
};

export type CreateAnalyticFirm500AllOfTwo = {
  statusCode: number;
  error: CreateAnalyticFirm500AllOfTwoError;
};

export type CreateAnalyticFirm500 = ApiErrorResponseDto & CreateAnalyticFirm500AllOf & CreateAnalyticFirm500AllOfTwo;

export type CreateAnalyticFirm500AllOfTwoErrorCode = typeof CreateAnalyticFirm500AllOfTwoErrorCode[keyof typeof CreateAnalyticFirm500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAnalyticFirm500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type CreateAnalyticFirm500AllOfTwoError = {
  status: number;
  code: CreateAnalyticFirm500AllOfTwoErrorCode;
};

export type CreateAnalyticFirm500AllOf = {
  error: ApiError;
};

export type CreateAnalyticFirm400AllOfTwoErrorCode = typeof CreateAnalyticFirm400AllOfTwoErrorCode[keyof typeof CreateAnalyticFirm400AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAnalyticFirm400AllOfTwoErrorCode = {
  AnalyticFirmAlreadyExistError: 'AnalyticFirmAlreadyExistError',
} as const;

export type CreateAnalyticFirm400AllOfTwoError = {
  status: number;
  code: CreateAnalyticFirm400AllOfTwoErrorCode;
};

export type CreateAnalyticFirm400AllOfTwo = {
  statusCode: number;
  error: CreateAnalyticFirm400AllOfTwoError;
};

export type CreateAnalyticFirm400AllOf = {
  error: AnalyticFirmAlreadyExistError;
};

export type CreateAnalyticFirm400 = ApiErrorResponseDto & CreateAnalyticFirm400AllOf & CreateAnalyticFirm400AllOfTwo;

export type CreateAnalyticFirm200 = {
  data: AnalyticFirmListResponseDto;
};

export type ReadAnalyticFirm500AllOfTwoErrorCode = typeof ReadAnalyticFirm500AllOfTwoErrorCode[keyof typeof ReadAnalyticFirm500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadAnalyticFirm500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ReadAnalyticFirm500AllOfTwoError = {
  status: number;
  code: ReadAnalyticFirm500AllOfTwoErrorCode;
};

export type ReadAnalyticFirm500AllOfTwo = {
  statusCode: number;
  error: ReadAnalyticFirm500AllOfTwoError;
};

export type ReadAnalyticFirm500AllOf = {
  error: ApiError;
};

export type ReadAnalyticFirm500 = ApiErrorResponseDto & ReadAnalyticFirm500AllOf & ReadAnalyticFirm500AllOfTwo;

export type ReadAnalyticFirm200 = {
  data: AnalyticFirmListResponseDto;
};

export type CreateAnalyticFirms500AllOfTwoErrorCode = typeof CreateAnalyticFirms500AllOfTwoErrorCode[keyof typeof CreateAnalyticFirms500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAnalyticFirms500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type CreateAnalyticFirms500AllOfTwoError = {
  status: number;
  code: CreateAnalyticFirms500AllOfTwoErrorCode;
};

export type CreateAnalyticFirms500AllOfTwo = {
  statusCode: number;
  error: CreateAnalyticFirms500AllOfTwoError;
};

export type CreateAnalyticFirms500AllOf = {
  error: ApiError;
};

export type CreateAnalyticFirms500 = ApiErrorResponseDto & CreateAnalyticFirms500AllOf & CreateAnalyticFirms500AllOfTwo;

export type CreateAnalyticFirms200 = {
  data: Function;
};

export type ListAnalyticFirms500AllOfTwoErrorCode = typeof ListAnalyticFirms500AllOfTwoErrorCode[keyof typeof ListAnalyticFirms500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAnalyticFirms500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListAnalyticFirms500AllOfTwoError = {
  status: number;
  code: ListAnalyticFirms500AllOfTwoErrorCode;
};

export type ListAnalyticFirms500AllOfTwo = {
  statusCode: number;
  error: ListAnalyticFirms500AllOfTwoError;
};

export type ListAnalyticFirms500AllOf = {
  error: ApiError;
};

export type ListAnalyticFirms500 = ApiErrorResponseDto & ListAnalyticFirms500AllOf & ListAnalyticFirms500AllOfTwo;

export type ListAnalyticFirms200 = {
  data: ListAnalyticFirmsResponseDto;
};

export type CreatePoolColors500AllOfTwoErrorCode = typeof CreatePoolColors500AllOfTwoErrorCode[keyof typeof CreatePoolColors500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreatePoolColors500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type CreatePoolColors500AllOfTwoError = {
  status: number;
  code: CreatePoolColors500AllOfTwoErrorCode;
};

export type CreatePoolColors500AllOfTwo = {
  statusCode: number;
  error: CreatePoolColors500AllOfTwoError;
};

export type CreatePoolColors500AllOf = {
  error: ApiError;
};

export type CreatePoolColors500 = ApiErrorResponseDto & CreatePoolColors500AllOf & CreatePoolColors500AllOfTwo;

export type CreatePoolColors200 = {
  data: Function;
};

export type GetInitialTraderState500AllOfTwoErrorCode = typeof GetInitialTraderState500AllOfTwoErrorCode[keyof typeof GetInitialTraderState500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInitialTraderState500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetInitialTraderState500AllOfTwoError = {
  status: number;
  code: GetInitialTraderState500AllOfTwoErrorCode;
};

export type GetInitialTraderState500AllOfTwo = {
  statusCode: number;
  error: GetInitialTraderState500AllOfTwoError;
};

export type GetInitialTraderState500AllOf = {
  error: ApiError;
};

export type GetInitialTraderState500 = ApiErrorResponseDto & GetInitialTraderState500AllOf & GetInitialTraderState500AllOfTwo;

export type GetInitialTraderState200 = {
  data: GetTraderInitialStateResponseDto;
};

export type ListStaffUsers500AllOfTwoErrorCode = typeof ListStaffUsers500AllOfTwoErrorCode[keyof typeof ListStaffUsers500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListStaffUsers500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListStaffUsers500AllOfTwoError = {
  status: number;
  code: ListStaffUsers500AllOfTwoErrorCode;
};

export type ListStaffUsers500AllOfTwo = {
  statusCode: number;
  error: ListStaffUsers500AllOfTwoError;
};

export type ListStaffUsers500AllOf = {
  error: ApiError;
};

export type ListStaffUsers500 = ApiErrorResponseDto & ListStaffUsers500AllOf & ListStaffUsers500AllOfTwo;

export type DeleteStaffUser500AllOfTwoErrorCode = typeof DeleteStaffUser500AllOfTwoErrorCode[keyof typeof DeleteStaffUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteStaffUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type DeleteStaffUser500AllOfTwoError = {
  status: number;
  code: DeleteStaffUser500AllOfTwoErrorCode;
};

export type DeleteStaffUser500AllOfTwo = {
  statusCode: number;
  error: DeleteStaffUser500AllOfTwoError;
};

export type DeleteStaffUser500AllOf = {
  error: ApiError;
};

export type DeleteStaffUser500 = ApiErrorResponseDto & DeleteStaffUser500AllOf & DeleteStaffUser500AllOfTwo;

export type DeleteStaffUser200 = {
  data: DeleteStaffUserResponseDto;
};

export type ReadStaffUser500AllOfTwoErrorCode = typeof ReadStaffUser500AllOfTwoErrorCode[keyof typeof ReadStaffUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadStaffUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ReadStaffUser500AllOfTwoError = {
  status: number;
  code: ReadStaffUser500AllOfTwoErrorCode;
};

export type ReadStaffUser500AllOfTwo = {
  statusCode: number;
  error: ReadStaffUser500AllOfTwoError;
};

export type ReadStaffUser500AllOf = {
  error: ApiError;
};

export type ReadStaffUser500 = ApiErrorResponseDto & ReadStaffUser500AllOf & ReadStaffUser500AllOfTwo;

export type ReadStaffUser200 = {
  data: ReadStaffUserResponseDto;
};

export type GetCurrentStaffUser500AllOfTwoErrorCode = typeof GetCurrentStaffUser500AllOfTwoErrorCode[keyof typeof GetCurrentStaffUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCurrentStaffUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetCurrentStaffUser500AllOfTwoError = {
  status: number;
  code: GetCurrentStaffUser500AllOfTwoErrorCode;
};

export type GetCurrentStaffUser500AllOfTwo = {
  statusCode: number;
  error: GetCurrentStaffUser500AllOfTwoError;
};

export type GetCurrentStaffUser500AllOf = {
  error: ApiError;
};

export type GetCurrentStaffUser500 = ApiErrorResponseDto & GetCurrentStaffUser500AllOf & GetCurrentStaffUser500AllOfTwo;

export type GetCurrentStaffUser200 = {
  data: TraderDto;
};

export type ListStaffRoleMapping500AllOfTwoErrorCode = typeof ListStaffRoleMapping500AllOfTwoErrorCode[keyof typeof ListStaffRoleMapping500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListStaffRoleMapping500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListStaffRoleMapping500AllOfTwoError = {
  status: number;
  code: ListStaffRoleMapping500AllOfTwoErrorCode;
};

export type ListStaffRoleMapping500AllOfTwo = {
  statusCode: number;
  error: ListStaffRoleMapping500AllOfTwoError;
};

export type ListStaffRoleMapping500AllOf = {
  error: ApiError;
};

export type ListStaffRoleMapping500 = ApiErrorResponseDto & ListStaffRoleMapping500AllOf & ListStaffRoleMapping500AllOfTwo;

export type DeleteStaffRoleMapping500AllOfTwoErrorCode = typeof DeleteStaffRoleMapping500AllOfTwoErrorCode[keyof typeof DeleteStaffRoleMapping500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteStaffRoleMapping500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type DeleteStaffRoleMapping500AllOfTwoError = {
  status: number;
  code: DeleteStaffRoleMapping500AllOfTwoErrorCode;
};

export type DeleteStaffRoleMapping500AllOfTwo = {
  statusCode: number;
  error: DeleteStaffRoleMapping500AllOfTwoError;
};

export type DeleteStaffRoleMapping500AllOf = {
  error: ApiError;
};

export type DeleteStaffRoleMapping500 = ApiErrorResponseDto & DeleteStaffRoleMapping500AllOf & DeleteStaffRoleMapping500AllOfTwo;

export type DeleteStaffRoleMapping200 = {
  data: DeleteStaffRoleMappingResponseDto;
};

export type UpdateStaffRoleMapping500AllOfTwoErrorCode = typeof UpdateStaffRoleMapping500AllOfTwoErrorCode[keyof typeof UpdateStaffRoleMapping500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateStaffRoleMapping500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type UpdateStaffRoleMapping500AllOfTwoError = {
  status: number;
  code: UpdateStaffRoleMapping500AllOfTwoErrorCode;
};

export type UpdateStaffRoleMapping500AllOfTwo = {
  statusCode: number;
  error: UpdateStaffRoleMapping500AllOfTwoError;
};

export type UpdateStaffRoleMapping500AllOf = {
  error: ApiError;
};

export type UpdateStaffRoleMapping500 = ApiErrorResponseDto & UpdateStaffRoleMapping500AllOf & UpdateStaffRoleMapping500AllOfTwo;

export type UpdateStaffRoleMapping200 = {
  data: UpdateStaffRoleMappingResponseDto;
};

export type ReadStaffRoleMapping500AllOfTwoError = {
  status: number;
  code: ReadStaffRoleMapping500AllOfTwoErrorCode;
};

export type ReadStaffRoleMapping500AllOfTwo = {
  statusCode: number;
  error: ReadStaffRoleMapping500AllOfTwoError;
};

export type ReadStaffRoleMapping500 = ApiErrorResponseDto & ReadStaffRoleMapping500AllOf & ReadStaffRoleMapping500AllOfTwo;

export type ReadStaffRoleMapping500AllOfTwoErrorCode = typeof ReadStaffRoleMapping500AllOfTwoErrorCode[keyof typeof ReadStaffRoleMapping500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadStaffRoleMapping500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ReadStaffRoleMapping500AllOf = {
  error: ApiError;
};

export type ReadStaffRoleMapping200 = {
  data: ReadStaffRoleMappingResponseDto;
};

export type CreateStaffRoleMapping500AllOfTwoErrorCode = typeof CreateStaffRoleMapping500AllOfTwoErrorCode[keyof typeof CreateStaffRoleMapping500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateStaffRoleMapping500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type CreateStaffRoleMapping500AllOfTwoError = {
  status: number;
  code: CreateStaffRoleMapping500AllOfTwoErrorCode;
};

export type CreateStaffRoleMapping500AllOfTwo = {
  statusCode: number;
  error: CreateStaffRoleMapping500AllOfTwoError;
};

export type CreateStaffRoleMapping500AllOf = {
  error: ApiError;
};

export type CreateStaffRoleMapping500 = ApiErrorResponseDto & CreateStaffRoleMapping500AllOf & CreateStaffRoleMapping500AllOfTwo;

export type CreateStaffRoleMapping200 = {
  data: CreateStaffRoleMappingResponseDto;
};

export type GenerateStaffUserSession500AllOfTwoErrorCode = typeof GenerateStaffUserSession500AllOfTwoErrorCode[keyof typeof GenerateStaffUserSession500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GenerateStaffUserSession500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GenerateStaffUserSession500AllOfTwoError = {
  status: number;
  code: GenerateStaffUserSession500AllOfTwoErrorCode;
};

export type GenerateStaffUserSession500AllOfTwo = {
  statusCode: number;
  error: GenerateStaffUserSession500AllOfTwoError;
};

export type GenerateStaffUserSession500AllOf = {
  error: ApiError;
};

export type GenerateStaffUserSession500 = ApiErrorResponseDto & GenerateStaffUserSession500AllOf & GenerateStaffUserSession500AllOfTwo;

export type GenerateStaffUserSession200 = {
  data: AuthTokensResponseDto;
};

export type GenerateEndUserSession500AllOfTwoErrorCode = typeof GenerateEndUserSession500AllOfTwoErrorCode[keyof typeof GenerateEndUserSession500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GenerateEndUserSession500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GenerateEndUserSession500AllOfTwoError = {
  status: number;
  code: GenerateEndUserSession500AllOfTwoErrorCode;
};

export type GenerateEndUserSession500AllOfTwo = {
  statusCode: number;
  error: GenerateEndUserSession500AllOfTwoError;
};

export type GenerateEndUserSession500AllOf = {
  error: ApiError;
};

export type GenerateEndUserSession500 = ApiErrorResponseDto & GenerateEndUserSession500AllOf & GenerateEndUserSession500AllOfTwo;

export type GenerateEndUserSession401AllOfTwoErrorCode = typeof GenerateEndUserSession401AllOfTwoErrorCode[keyof typeof GenerateEndUserSession401AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GenerateEndUserSession401AllOfTwoErrorCode = {
  OutBusinessWorkingHoursError: 'OutBusinessWorkingHoursError',
} as const;

export type GenerateEndUserSession401AllOfTwoError = {
  status: number;
  code: GenerateEndUserSession401AllOfTwoErrorCode;
};

export type GenerateEndUserSession401AllOfTwo = {
  statusCode: number;
  error: GenerateEndUserSession401AllOfTwoError;
};

export type GenerateEndUserSession401AllOf = {
  error: OutBusinessWorkingHoursError;
};

export type GenerateEndUserSession401 = ApiErrorResponseDto & GenerateEndUserSession401AllOf & GenerateEndUserSession401AllOfTwo;

export type GenerateEndUserSession200 = {
  data: AuthTokensResponseDto;
};

export type MicrosoftRedirect500AllOfTwoErrorCode = typeof MicrosoftRedirect500AllOfTwoErrorCode[keyof typeof MicrosoftRedirect500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MicrosoftRedirect500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type MicrosoftRedirect500AllOfTwoError = {
  status: number;
  code: MicrosoftRedirect500AllOfTwoErrorCode;
};

export type MicrosoftRedirect500AllOfTwo = {
  statusCode: number;
  error: MicrosoftRedirect500AllOfTwoError;
};

export type MicrosoftRedirect500AllOf = {
  error: ApiError;
};

export type MicrosoftRedirect500 = ApiErrorResponseDto & MicrosoftRedirect500AllOf & MicrosoftRedirect500AllOfTwo;

export type MicrosoftRedirect200 = {
  data: AuthTokensResponseDto;
};

export type MicrosoftSignIn500AllOfTwoErrorCode = typeof MicrosoftSignIn500AllOfTwoErrorCode[keyof typeof MicrosoftSignIn500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MicrosoftSignIn500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type MicrosoftSignIn500AllOfTwoError = {
  status: number;
  code: MicrosoftSignIn500AllOfTwoErrorCode;
};

export type MicrosoftSignIn500AllOfTwo = {
  statusCode: number;
  error: MicrosoftSignIn500AllOfTwoError;
};

export type MicrosoftSignIn500AllOf = {
  error: ApiError;
};

export type MicrosoftSignIn500 = ApiErrorResponseDto & MicrosoftSignIn500AllOf & MicrosoftSignIn500AllOfTwo;

export type MicrosoftSignIn200 = {
  data: MicrosoftAuthResponseDto;
};

export type MicrosoftSignInParams = { redirect_to: string };

export type RefreshToken500AllOfTwo = {
  statusCode: number;
  error: RefreshToken500AllOfTwoError;
};

export type RefreshToken500 = ApiErrorResponseDto & RefreshToken500AllOf & RefreshToken500AllOfTwo;

export type RefreshToken500AllOfTwoErrorCode = typeof RefreshToken500AllOfTwoErrorCode[keyof typeof RefreshToken500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefreshToken500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type RefreshToken500AllOfTwoError = {
  status: number;
  code: RefreshToken500AllOfTwoErrorCode;
};

export type RefreshToken500AllOf = {
  error: ApiError;
};

export type RefreshToken404AllOfTwoErrorCode = typeof RefreshToken404AllOfTwoErrorCode[keyof typeof RefreshToken404AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefreshToken404AllOfTwoErrorCode = {
  null: 'null',
} as const;

export type RefreshToken404AllOfTwoError = {
  status: number;
  code: RefreshToken404AllOfTwoErrorCode;
};

export type RefreshToken404AllOfTwo = {
  statusCode: number;
  error: RefreshToken404AllOfTwoError;
};

export type RefreshToken404AllOf = {
  error: EndUserNotFoundByUserIdError;
};

export type RefreshToken404 = ApiErrorResponseDto & RefreshToken404AllOf & RefreshToken404AllOfTwo;

export type RefreshToken401AllOfTwoErrorCode = typeof RefreshToken401AllOfTwoErrorCode[keyof typeof RefreshToken401AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefreshToken401AllOfTwoErrorCode = {
  OutBusinessWorkingHoursError: 'OutBusinessWorkingHoursError',
} as const;

export type RefreshToken401AllOfTwoError = {
  status: number;
  code: RefreshToken401AllOfTwoErrorCode;
};

export type RefreshToken401AllOfTwo = {
  statusCode: number;
  error: RefreshToken401AllOfTwoError;
};

export type RefreshToken401AllOf = {
  error: OutBusinessWorkingHoursError;
};

export type RefreshToken401 = ApiErrorResponseDto & RefreshToken401AllOf & RefreshToken401AllOfTwo;

export type RefreshToken200 = {
  data: AuthTokensResponseDto;
};

export type TraderAccessToken500AllOfTwoErrorCode = typeof TraderAccessToken500AllOfTwoErrorCode[keyof typeof TraderAccessToken500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TraderAccessToken500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type TraderAccessToken500AllOfTwoError = {
  status: number;
  code: TraderAccessToken500AllOfTwoErrorCode;
};

export type TraderAccessToken500AllOfTwo = {
  statusCode: number;
  error: TraderAccessToken500AllOfTwoError;
};

export type TraderAccessToken500AllOf = {
  error: ApiError;
};

export type TraderAccessToken500 = ApiErrorResponseDto & TraderAccessToken500AllOf & TraderAccessToken500AllOfTwo;

export type TraderAccessToken200 = {
  data: EndUserAccessTokenResponseDto;
};

export type EndUserAccessToken500AllOfTwoErrorCode = typeof EndUserAccessToken500AllOfTwoErrorCode[keyof typeof EndUserAccessToken500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EndUserAccessToken500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type EndUserAccessToken500AllOfTwoError = {
  status: number;
  code: EndUserAccessToken500AllOfTwoErrorCode;
};

export type EndUserAccessToken500AllOfTwo = {
  statusCode: number;
  error: EndUserAccessToken500AllOfTwoError;
};

export type EndUserAccessToken500AllOf = {
  error: ApiError;
};

export type EndUserAccessToken500 = ApiErrorResponseDto & EndUserAccessToken500AllOf & EndUserAccessToken500AllOfTwo;

export type EndUserAccessToken401AllOfTwoErrorCode = typeof EndUserAccessToken401AllOfTwoErrorCode[keyof typeof EndUserAccessToken401AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EndUserAccessToken401AllOfTwoErrorCode = {
  OutBusinessWorkingHoursError: 'OutBusinessWorkingHoursError',
} as const;

export type EndUserAccessToken401AllOfTwoError = {
  status: number;
  code: EndUserAccessToken401AllOfTwoErrorCode;
};

export type EndUserAccessToken401AllOfTwo = {
  statusCode: number;
  error: EndUserAccessToken401AllOfTwoError;
};

export type EndUserAccessToken401AllOf = {
  error: OutBusinessWorkingHoursError;
};

export type EndUserAccessToken401 = ApiErrorResponseDto & EndUserAccessToken401AllOf & EndUserAccessToken401AllOfTwo;

export type EndUserAccessToken200 = {
  data: EndUserAccessTokenResponseDto;
};

export type VerifyOtp500AllOfTwoErrorCode = typeof VerifyOtp500AllOfTwoErrorCode[keyof typeof VerifyOtp500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerifyOtp500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type VerifyOtp500AllOfTwoError = {
  status: number;
  code: VerifyOtp500AllOfTwoErrorCode;
};

export type VerifyOtp500AllOfTwo = {
  statusCode: number;
  error: VerifyOtp500AllOfTwoError;
};

export type VerifyOtp500AllOf = {
  error: ApiError;
};

export type VerifyOtp500 = ApiErrorResponseDto & VerifyOtp500AllOf & VerifyOtp500AllOfTwo;

export type VerifyOtp401AllOfTwoErrorCode = typeof VerifyOtp401AllOfTwoErrorCode[keyof typeof VerifyOtp401AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerifyOtp401AllOfTwoErrorCode = {
  OutBusinessWorkingHoursError: 'OutBusinessWorkingHoursError',
} as const;

export type VerifyOtp401AllOfTwoError = {
  status: number;
  code: VerifyOtp401AllOfTwoErrorCode;
};

export type VerifyOtp401AllOfTwo = {
  statusCode: number;
  error: VerifyOtp401AllOfTwoError;
};

export type VerifyOtp401AllOf = {
  error: OutBusinessWorkingHoursError;
};

export type VerifyOtp401 = ApiErrorResponseDto & VerifyOtp401AllOf & VerifyOtp401AllOfTwo;

export type VerifyOtp400AllOfTwoErrorCode = typeof VerifyOtp400AllOfTwoErrorCode[keyof typeof VerifyOtp400AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerifyOtp400AllOfTwoErrorCode = {
  OTPFailedToVerifyCodeError: 'OTPFailedToVerifyCodeError',
} as const;

export type VerifyOtp400AllOfTwoError = {
  status: number;
  code: VerifyOtp400AllOfTwoErrorCode;
};

export type VerifyOtp400AllOfTwo = {
  statusCode: number;
  error: VerifyOtp400AllOfTwoError;
};

export type VerifyOtp400AllOf = {
  error: OTPFailedToVerifyCodeError;
};

export type VerifyOtp400 = ApiErrorResponseDto & VerifyOtp400AllOf & VerifyOtp400AllOfTwo;

export type VerifyOtp200 = {
  data: AuthTokensResponseDto;
};

export type SendOtp500AllOfTwoErrorCode = typeof SendOtp500AllOfTwoErrorCode[keyof typeof SendOtp500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendOtp500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type SendOtp500AllOfTwoError = {
  status: number;
  code: SendOtp500AllOfTwoErrorCode;
};

export type SendOtp500AllOfTwo = {
  statusCode: number;
  error: SendOtp500AllOfTwoError;
};

export type SendOtp500AllOf = {
  error: ApiError;
};

export type SendOtp500 = ApiErrorResponseDto & SendOtp500AllOf & SendOtp500AllOfTwo;

export type SendOtp401AllOfTwoErrorCode = typeof SendOtp401AllOfTwoErrorCode[keyof typeof SendOtp401AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendOtp401AllOfTwoErrorCode = {
  OutBusinessWorkingHoursError: 'OutBusinessWorkingHoursError',
} as const;

export type SendOtp401AllOfTwoError = {
  status: number;
  code: SendOtp401AllOfTwoErrorCode;
};

export type SendOtp401AllOfTwo = {
  statusCode: number;
  error: SendOtp401AllOfTwoError;
};

export type SendOtp401AllOf = {
  error: OutBusinessWorkingHoursError;
};

export type SendOtp401 = ApiErrorResponseDto & SendOtp401AllOf & SendOtp401AllOfTwo;

export type SendOtp400AllOfTwoErrorCode = typeof SendOtp400AllOfTwoErrorCode[keyof typeof SendOtp400AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendOtp400AllOfTwoErrorCode = {
  OTPFailedToSendVerificationCodeError: 'OTPFailedToSendVerificationCodeError',
} as const;

export type SendOtp400AllOfTwoError = {
  status: number;
  code: SendOtp400AllOfTwoErrorCode;
};

export type SendOtp400AllOfTwo = {
  statusCode: number;
  error: SendOtp400AllOfTwoError;
};

export type SendOtp400AllOf = {
  error: OTPFailedToSendVerificationCodeError;
};

export type SendOtp400 = ApiErrorResponseDto & SendOtp400AllOf & SendOtp400AllOfTwo;

export type SendOtp200 = {
  data: AuthTokensResponseDto;
};

export type SetPassword500AllOfTwoErrorCode = typeof SetPassword500AllOfTwoErrorCode[keyof typeof SetPassword500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetPassword500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type SetPassword500AllOfTwoError = {
  status: number;
  code: SetPassword500AllOfTwoErrorCode;
};

export type SetPassword500AllOfTwo = {
  statusCode: number;
  error: SetPassword500AllOfTwoError;
};

export type SetPassword500AllOf = {
  error: ApiError;
};

export type SetPassword500 = ApiErrorResponseDto & SetPassword500AllOf & SetPassword500AllOfTwo;

export type SetPassword400AllOfTwoErrorCode = typeof SetPassword400AllOfTwoErrorCode[keyof typeof SetPassword400AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetPassword400AllOfTwoErrorCode = {
  EndUserAlreadyExistError: 'EndUserAlreadyExistError',
} as const;

export type SetPassword400AllOfTwoError = {
  status: number;
  code: SetPassword400AllOfTwoErrorCode;
};

export type SetPassword400AllOfTwo = {
  statusCode: number;
  error: SetPassword400AllOfTwoError;
};

export type SetPassword400AllOf = {
  error: EndUserAlreadyExistError;
};

export type SetPassword400 = ApiErrorResponseDto & SetPassword400AllOf & SetPassword400AllOfTwo;

export type SetPassword200 = {
  data: ResetPasswordResponseDto;
};

export type ResetEndUserPassword500AllOfTwoErrorCode = typeof ResetEndUserPassword500AllOfTwoErrorCode[keyof typeof ResetEndUserPassword500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResetEndUserPassword500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ResetEndUserPassword500AllOfTwoError = {
  status: number;
  code: ResetEndUserPassword500AllOfTwoErrorCode;
};

export type ResetEndUserPassword500AllOfTwo = {
  statusCode: number;
  error: ResetEndUserPassword500AllOfTwoError;
};

export type ResetEndUserPassword500AllOf = {
  error: ApiError;
};

export type ResetEndUserPassword500 = ApiErrorResponseDto & ResetEndUserPassword500AllOf & ResetEndUserPassword500AllOfTwo;

export type ResetEndUserPassword200 = {
  data: DefaultDto;
};

export type ForgotPassword500AllOfTwo = {
  statusCode: number;
  error: ForgotPassword500AllOfTwoError;
};

export type ForgotPassword500 = ApiErrorResponseDto & ForgotPassword500AllOf & ForgotPassword500AllOfTwo;

export type ForgotPassword500AllOfTwoErrorCode = typeof ForgotPassword500AllOfTwoErrorCode[keyof typeof ForgotPassword500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ForgotPassword500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ForgotPassword500AllOfTwoError = {
  status: number;
  code: ForgotPassword500AllOfTwoErrorCode;
};

export type ForgotPassword500AllOf = {
  error: ApiError;
};

export type ForgotPassword200 = {
  data: DefaultDto;
};

export type SendInvite500AllOfTwoErrorCode = typeof SendInvite500AllOfTwoErrorCode[keyof typeof SendInvite500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendInvite500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type SendInvite500AllOfTwoError = {
  status: number;
  code: SendInvite500AllOfTwoErrorCode;
};

export type SendInvite500AllOfTwo = {
  statusCode: number;
  error: SendInvite500AllOfTwoError;
};

export type SendInvite500AllOf = {
  error: ApiError;
};

export type SendInvite500 = ApiErrorResponseDto & SendInvite500AllOf & SendInvite500AllOfTwo;

export type SendInvite400AllOfTwoErrorCode = typeof SendInvite400AllOfTwoErrorCode[keyof typeof SendInvite400AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendInvite400AllOfTwoErrorCode = {
  EmailAlreadyExistError: 'EmailAlreadyExistError',
} as const;

export type SendInvite400AllOfTwoError = {
  status: number;
  code: SendInvite400AllOfTwoErrorCode;
};

export type SendInvite400AllOfTwo = {
  statusCode: number;
  error: SendInvite400AllOfTwoError;
};

export type SendInvite400AllOf = {
  error: EmailAlreadyExistError;
};

export type SendInvite400 = ApiErrorResponseDto & SendInvite400AllOf & SendInvite400AllOfTwo;

export type SendInvite200 = {
  data: DefaultDto;
};

export type ListSystemSetting500AllOfTwoErrorCode = typeof ListSystemSetting500AllOfTwoErrorCode[keyof typeof ListSystemSetting500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListSystemSetting500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListSystemSetting500AllOfTwoError = {
  status: number;
  code: ListSystemSetting500AllOfTwoErrorCode;
};

export type ListSystemSetting500AllOfTwo = {
  statusCode: number;
  error: ListSystemSetting500AllOfTwoError;
};

export type ListSystemSetting500AllOf = {
  error: ApiError;
};

export type ListSystemSetting500 = ApiErrorResponseDto & ListSystemSetting500AllOf & ListSystemSetting500AllOfTwo;

export type ListSystemSetting200 = {
  data: ListSystemSettingsResponseDto;
};

export type DeleteSystemSetting500AllOfTwoErrorCode = typeof DeleteSystemSetting500AllOfTwoErrorCode[keyof typeof DeleteSystemSetting500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteSystemSetting500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type DeleteSystemSetting500AllOfTwoError = {
  status: number;
  code: DeleteSystemSetting500AllOfTwoErrorCode;
};

export type DeleteSystemSetting500AllOfTwo = {
  statusCode: number;
  error: DeleteSystemSetting500AllOfTwoError;
};

export type DeleteSystemSetting500AllOf = {
  error: ApiError;
};

export type DeleteSystemSetting500 = ApiErrorResponseDto & DeleteSystemSetting500AllOf & DeleteSystemSetting500AllOfTwo;

export type DeleteSystemSetting200 = {
  data: DeleteSystemSettingResponseDto;
};

export type UpsertSystemSetting500AllOfTwoErrorCode = typeof UpsertSystemSetting500AllOfTwoErrorCode[keyof typeof UpsertSystemSetting500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpsertSystemSetting500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type UpsertSystemSetting500AllOfTwoError = {
  status: number;
  code: UpsertSystemSetting500AllOfTwoErrorCode;
};

export type UpsertSystemSetting500AllOfTwo = {
  statusCode: number;
  error: UpsertSystemSetting500AllOfTwoError;
};

export type UpsertSystemSetting500AllOf = {
  error: ApiError;
};

export type UpsertSystemSetting500 = ApiErrorResponseDto & UpsertSystemSetting500AllOf & UpsertSystemSetting500AllOfTwo;

export type UpsertSystemSetting200 = {
  data: UpsertSystemSettingResponseDto;
};

export type ListCoupons500AllOfTwoErrorCode = typeof ListCoupons500AllOfTwoErrorCode[keyof typeof ListCoupons500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCoupons500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListCoupons500AllOfTwoError = {
  status: number;
  code: ListCoupons500AllOfTwoErrorCode;
};

export type ListCoupons500AllOfTwo = {
  statusCode: number;
  error: ListCoupons500AllOfTwoError;
};

export type ListCoupons500AllOf = {
  error: ApiError;
};

export type ListCoupons500 = ApiErrorResponseDto & ListCoupons500AllOf & ListCoupons500AllOfTwo;

export type ListCoupons200 = {
  data: ListProductCouponsResponseDto;
};

export type UpdateCoupon500AllOfTwo = {
  statusCode: number;
  error: UpdateCoupon500AllOfTwoError;
};

export type UpdateCoupon500 = ApiErrorResponseDto & UpdateCoupon500AllOf & UpdateCoupon500AllOfTwo;

export type UpdateCoupon500AllOfTwoErrorCode = typeof UpdateCoupon500AllOfTwoErrorCode[keyof typeof UpdateCoupon500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCoupon500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type UpdateCoupon500AllOfTwoError = {
  status: number;
  code: UpdateCoupon500AllOfTwoErrorCode;
};

export type UpdateCoupon500AllOf = {
  error: ApiError;
};

export type UpdateCoupon404AllOfTwoErrorCode = typeof UpdateCoupon404AllOfTwoErrorCode[keyof typeof UpdateCoupon404AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCoupon404AllOfTwoErrorCode = {
  ProductCouponNotFoundError: 'ProductCouponNotFoundError',
} as const;

export type UpdateCoupon404AllOfTwoError = {
  status: number;
  code: UpdateCoupon404AllOfTwoErrorCode;
};

export type UpdateCoupon404AllOfTwo = {
  statusCode: number;
  error: UpdateCoupon404AllOfTwoError;
};

export type UpdateCoupon404AllOf = {
  error: ProductCouponNotFoundError;
};

export type UpdateCoupon404 = ApiErrorResponseDto & UpdateCoupon404AllOf & UpdateCoupon404AllOfTwo;

export type UpdateCoupon200 = {
  data: DefaultDto;
};

export type CreateCoupon500AllOfTwoErrorCode = typeof CreateCoupon500AllOfTwoErrorCode[keyof typeof CreateCoupon500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCoupon500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type CreateCoupon500AllOfTwoError = {
  status: number;
  code: CreateCoupon500AllOfTwoErrorCode;
};

export type CreateCoupon500AllOfTwo = {
  statusCode: number;
  error: CreateCoupon500AllOfTwoError;
};

export type CreateCoupon500AllOf = {
  error: ApiError;
};

export type CreateCoupon500 = ApiErrorResponseDto & CreateCoupon500AllOf & CreateCoupon500AllOfTwo;

export type CreateCoupon400AllOfTwoErrorCode = typeof CreateCoupon400AllOfTwoErrorCode[keyof typeof CreateCoupon400AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCoupon400AllOfTwoErrorCode = {
  ProductCouponValueCollisionError: 'ProductCouponValueCollisionError',
} as const;

export type CreateCoupon400AllOfTwoError = {
  status: number;
  code: CreateCoupon400AllOfTwoErrorCode;
};

export type CreateCoupon400AllOfTwo = {
  statusCode: number;
  error: CreateCoupon400AllOfTwoError;
};

export type CreateCoupon400AllOf = {
  error: ProductCouponValueCollisionError;
};

export type CreateCoupon400 = ApiErrorResponseDto & CreateCoupon400AllOf & CreateCoupon400AllOfTwo;

export type CreateCoupon200 = {
  data: CreateProductCouponResponseDto;
};

export type GetProductList500AllOfTwoErrorCode = typeof GetProductList500AllOfTwoErrorCode[keyof typeof GetProductList500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProductList500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetProductList500AllOfTwoError = {
  status: number;
  code: GetProductList500AllOfTwoErrorCode;
};

export type GetProductList500AllOfTwo = {
  statusCode: number;
  error: GetProductList500AllOfTwoError;
};

export type GetProductList500AllOf = {
  error: ApiError;
};

export type GetProductList500 = ApiErrorResponseDto & GetProductList500AllOf & GetProductList500AllOfTwo;

export type GetProductList200 = {
  data: ListProductResponseDto;
};

export type ListPoolColors500AllOfTwoErrorCode = typeof ListPoolColors500AllOfTwoErrorCode[keyof typeof ListPoolColors500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPoolColors500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListPoolColors500AllOfTwoError = {
  status: number;
  code: ListPoolColors500AllOfTwoErrorCode;
};

export type ListPoolColors500AllOfTwo = {
  statusCode: number;
  error: ListPoolColors500AllOfTwoError;
};

export type ListPoolColors500AllOf = {
  error: ApiError;
};

export type ListPoolColors500 = ApiErrorResponseDto & ListPoolColors500AllOf & ListPoolColors500AllOfTwo;

export type ListPoolColors200 = {
  data: ListPoolColorResponseDto;
};

export type GetEndUserTradeHistory500AllOfTwoErrorCode = typeof GetEndUserTradeHistory500AllOfTwoErrorCode[keyof typeof GetEndUserTradeHistory500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEndUserTradeHistory500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetEndUserTradeHistory500AllOfTwoError = {
  status: number;
  code: GetEndUserTradeHistory500AllOfTwoErrorCode;
};

export type GetEndUserTradeHistory500AllOfTwo = {
  statusCode: number;
  error: GetEndUserTradeHistory500AllOfTwoError;
};

export type GetEndUserTradeHistory500AllOf = {
  error: ApiError;
};

export type GetEndUserTradeHistory500 = ApiErrorResponseDto & GetEndUserTradeHistory500AllOf & GetEndUserTradeHistory500AllOfTwo;

export type GetEndUserTradeHistory200 = {
  data: TradeRequestsDto;
};

export type GetAnalyticFirmAssociates500AllOfTwoError = {
  status: number;
  code: GetAnalyticFirmAssociates500AllOfTwoErrorCode;
};

export type GetAnalyticFirmAssociates500AllOfTwo = {
  statusCode: number;
  error: GetAnalyticFirmAssociates500AllOfTwoError;
};

export type GetAnalyticFirmAssociates500AllOfTwoErrorCode = typeof GetAnalyticFirmAssociates500AllOfTwoErrorCode[keyof typeof GetAnalyticFirmAssociates500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAnalyticFirmAssociates500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetAnalyticFirmAssociates500AllOf = {
  error: ApiError;
};

export type GetAnalyticFirmAssociates200 = {
  data: GetEndUserAnalyticFirmAssociateResponseDto;
};

export type GetInitialEndUserState500AllOfTwoErrorCode = typeof GetInitialEndUserState500AllOfTwoErrorCode[keyof typeof GetInitialEndUserState500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInitialEndUserState500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetInitialEndUserState500AllOfTwoError = {
  status: number;
  code: GetInitialEndUserState500AllOfTwoErrorCode;
};

export type GetInitialEndUserState500AllOfTwo = {
  statusCode: number;
  error: GetInitialEndUserState500AllOfTwoError;
};

export type GetInitialEndUserState500AllOf = {
  error: ApiError;
};

export type GetInitialEndUserState500 = ApiErrorResponseDto & GetInitialEndUserState500AllOf & GetInitialEndUserState500AllOfTwo;

export type GetInitialEndUserState200 = {
  data: GetEndUserInitialStateResponseDto;
};

export type ListEndUsers500AllOfTwoErrorCode = typeof ListEndUsers500AllOfTwoErrorCode[keyof typeof ListEndUsers500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEndUsers500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ListEndUsers500AllOfTwoError = {
  status: number;
  code: ListEndUsers500AllOfTwoErrorCode;
};

export type ListEndUsers500AllOfTwo = {
  statusCode: number;
  error: ListEndUsers500AllOfTwoError;
};

export type ListEndUsers500AllOf = {
  error: ApiError;
};

export type ListEndUsers500 = ApiErrorResponseDto & ListEndUsers500AllOf & ListEndUsers500AllOfTwo;

export type DeleteEndUser500AllOfTwoErrorCode = typeof DeleteEndUser500AllOfTwoErrorCode[keyof typeof DeleteEndUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteEndUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type DeleteEndUser500AllOfTwoError = {
  status: number;
  code: DeleteEndUser500AllOfTwoErrorCode;
};

export type DeleteEndUser500AllOfTwo = {
  statusCode: number;
  error: DeleteEndUser500AllOfTwoError;
};

export type DeleteEndUser500AllOf = {
  error: ApiError;
};

export type DeleteEndUser500 = ApiErrorResponseDto & DeleteEndUser500AllOf & DeleteEndUser500AllOfTwo;

export type DeleteEndUser200 = {
  data: DeleteEndUserResponseDto;
};

export type UpdateEndUser500AllOfTwoErrorCode = typeof UpdateEndUser500AllOfTwoErrorCode[keyof typeof UpdateEndUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateEndUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type UpdateEndUser500AllOfTwoError = {
  status: number;
  code: UpdateEndUser500AllOfTwoErrorCode;
};

export type UpdateEndUser500AllOfTwo = {
  statusCode: number;
  error: UpdateEndUser500AllOfTwoError;
};

export type UpdateEndUser500AllOf = {
  error: ApiError;
};

export type UpdateEndUser500 = ApiErrorResponseDto & UpdateEndUser500AllOf & UpdateEndUser500AllOfTwo;

export type UpdateEndUser200 = {
  data: UpdateEndUserResponseDto;
};

export type ReadEndUser500AllOfTwoErrorCode = typeof ReadEndUser500AllOfTwoErrorCode[keyof typeof ReadEndUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadEndUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type ReadEndUser500AllOfTwoError = {
  status: number;
  code: ReadEndUser500AllOfTwoErrorCode;
};

export type ReadEndUser500AllOfTwo = {
  statusCode: number;
  error: ReadEndUser500AllOfTwoError;
};

export type ReadEndUser500AllOf = {
  error: ApiError;
};

export type ReadEndUser500 = ApiErrorResponseDto & ReadEndUser500AllOf & ReadEndUser500AllOfTwo;

export type ReadEndUser200 = {
  data: ReadEndUserResponseDto;
};

export type GetCurrentEndUser500AllOfTwoErrorCode = typeof GetCurrentEndUser500AllOfTwoErrorCode[keyof typeof GetCurrentEndUser500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCurrentEndUser500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetCurrentEndUser500AllOfTwoError = {
  status: number;
  code: GetCurrentEndUser500AllOfTwoErrorCode;
};

export type GetCurrentEndUser500AllOfTwo = {
  statusCode: number;
  error: GetCurrentEndUser500AllOfTwoError;
};

export type GetCurrentEndUser500AllOf = {
  error: ApiError;
};

export type GetCurrentEndUser500 = ApiErrorResponseDto & GetCurrentEndUser500AllOf & GetCurrentEndUser500AllOfTwo;

export type GetCurrentEndUser200 = {
  data: EndUserSelfResponse;
};

export type GetMinimumAppVersion500AllOfTwoError = {
  status: number;
  code: GetMinimumAppVersion500AllOfTwoErrorCode;
};

export type GetMinimumAppVersion500AllOfTwo = {
  statusCode: number;
  error: GetMinimumAppVersion500AllOfTwoError;
};

export type GetMinimumAppVersion500 = ApiErrorResponseDto & GetMinimumAppVersion500AllOf & GetMinimumAppVersion500AllOfTwo;

export type GetMinimumAppVersion500AllOfTwoErrorCode = typeof GetMinimumAppVersion500AllOfTwoErrorCode[keyof typeof GetMinimumAppVersion500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMinimumAppVersion500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type GetMinimumAppVersion500AllOf = {
  error: ApiError;
};

export type GetMinimumAppVersion200 = {
  data: MinimumAppVersionResponseDto;
};

export type RedirectToResetPasswordDeepLink500AllOfTwoErrorCode = typeof RedirectToResetPasswordDeepLink500AllOfTwoErrorCode[keyof typeof RedirectToResetPasswordDeepLink500AllOfTwoErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RedirectToResetPasswordDeepLink500AllOfTwoErrorCode = {
  UnexpectedError: 'UnexpectedError',
} as const;

export type RedirectToResetPasswordDeepLink500AllOfTwoError = {
  status: number;
  code: RedirectToResetPasswordDeepLink500AllOfTwoErrorCode;
};

export type RedirectToResetPasswordDeepLink500AllOfTwo = {
  statusCode: number;
  error: RedirectToResetPasswordDeepLink500AllOfTwoError;
};

export type RedirectToResetPasswordDeepLink500AllOf = {
  error: ApiError;
};

export type RedirectToResetPasswordDeepLink500 = ApiErrorResponseDto & RedirectToResetPasswordDeepLink500AllOf & RedirectToResetPasswordDeepLink500AllOfTwo;

export type RedirectToResetPasswordDeepLink200 = {
  data: Function;
};

export interface DeleteTradeRequestEventResponseDto {
  id: string;
}

export interface DeleteTradeRequestEventRequestDto {
  id: string;
}

export type UpdateTradeRequestEventResponseDtoCreatedAt = string | string;

export interface UpdateTradeRequestEventResponseDto {
  id: string;
  createdAt?: UpdateTradeRequestEventResponseDtoCreatedAt;
  tradeRequestId: string;
}

export interface UpdateTradeRequestEventRequestDto {
  id: string;
}

export type ReadTradeRequestEventResponseDtoCreatedAt = string | string;

export interface ReadTradeRequestEventResponseDto {
  id: string;
  createdAt?: ReadTradeRequestEventResponseDtoCreatedAt;
  tradeRequestId: string;
}

export interface ReadTradeRequestEventRequestDto {
  id: string;
}

export type CreateTradeRequestEventResponseDtoCreatedAt = string | string;

export interface CreateTradeRequestEventResponseDto {
  id: string;
  createdAt?: CreateTradeRequestEventResponseDtoCreatedAt;
  tradeRequestId: string;
}

export interface CreateTradeRequestEventRequestDto {
  tradeRequestId: string;
}

export interface ReadStaffRoleMappingRequestDto {
  id: string;
}

export interface SetPasswordResponseDto {
  code: string;
  signature: string;
}

export interface Profile {
  analyticsFirmId?: unknown;
}

export interface OtpVerifyCodeRequestDto {
  to: string;
  code: string;
}

export interface SendOtpResponseDto {
  jwt: string;
}

export interface SendOtpRequestDto {
  to: string;
  identifier: string;
  credential: string;
}

export type StaffUserDtoInactivatedAt = string | string;

export type StaffUserDtoUpdatedAt = string | string;

export type StaffUserDtoCreatedAt = string | string;

export interface StaffUserDto {
  id: string;
  createdAt: StaffUserDtoCreatedAt;
  updatedAt: StaffUserDtoUpdatedAt;
  inactivatedAt?: StaffUserDtoInactivatedAt;
  externalId: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface StaffUsersDto {
  staffUsers: StaffUserDto[];
}

export interface PaginationStaffUserMetadataDto {
  pageSize: number;
  currentCursor: string;
  nextCursor: string;
  totalPages: number;
}

export interface GetStaffUserTradeHistoryRequestDto {
  nextCursor?: string;
  limit?: number;
}

export type ListStaffUserResponseDtoInactivatedAt = string | string;

export type ListStaffUserResponseDtoUpdatedAt = string | string;

export type ListStaffUserResponseDtoCreatedAt = string | string;

export interface ListStaffUserResponseDto {
  id: string;
  createdAt: ListStaffUserResponseDtoCreatedAt;
  updatedAt: ListStaffUserResponseDtoUpdatedAt;
  inactivatedAt?: ListStaffUserResponseDtoInactivatedAt;
  externalId: string;
}

export interface PoolColorRecordRequestDto {
  type: string;
  coupon: number;
  value: string;
}

export interface AnalyticFirmRecordRequestDto {
  externalId: string;
}

export interface ReadAnalyticFirmRequestDto {
  id: string;
}

export interface AttachAnalyticFirmEndUserRequestDto {
  externalId: string;
  firstName: string;
  lastName: string;
}

export type AnalyticFirmAssociatedEndUserResponseDtoRole = typeof AnalyticFirmAssociatedEndUserResponseDtoRole[keyof typeof AnalyticFirmAssociatedEndUserResponseDtoRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalyticFirmAssociatedEndUserResponseDtoRole = {
  ANALYTIC_FIRM: 'ANALYTIC_FIRM',
  ASSOCIATED_USER: 'ASSOCIATED_USER',
  ASSOCIATED_NO_ACCESS: 'ASSOCIATED_NO_ACCESS',
  ASSOCIATED_READ_ONLY: 'ASSOCIATED_READ_ONLY',
} as const;

export interface AnalyticFirmAssociatedUserResponseDto {
  id: string;
  firstName: string;
  lastName: string;
}

export interface AnalyticFirmAssociatedEndUserResponseDto {
  id: string;
  externalId: string;
  role: AnalyticFirmAssociatedEndUserResponseDtoRole;
  user: AnalyticFirmAssociatedUserResponseDto;
}

export type UpdateStaffUserResponseDtoInactivatedAt = string | string;

export type UpdateStaffUserResponseDtoUpdatedAt = string | string;

export type UpdateStaffUserResponseDtoCreatedAt = string | string;

export interface UpdateStaffUserResponseDto {
  id: string;
  createdAt: UpdateStaffUserResponseDtoCreatedAt;
  updatedAt: UpdateStaffUserResponseDtoUpdatedAt;
  inactivatedAt?: UpdateStaffUserResponseDtoInactivatedAt;
  externalId: string;
}

export type UpdateStaffUserRequestDtoInactivatedAt = string | string;

export interface UpdateStaffUserRequestDto {
  id: string;
  inactivatedAt?: UpdateStaffUserRequestDtoInactivatedAt;
}

export interface ReadStaffUserRequestDto {
  id: string;
}

export type UpsertStaffUserResponseDtoRolesItem = typeof UpsertStaffUserResponseDtoRolesItem[keyof typeof UpsertStaffUserResponseDtoRolesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpsertStaffUserResponseDtoRolesItem = {
  ADMIN: 'ADMIN',
  TRADER: 'TRADER',
} as const;

export type UpsertStaffUserResponseDtoInactivatedAt = string | string;

export type UpsertStaffUserResponseDtoUpdatedAt = string | string;

export type UpsertStaffUserResponseDtoCreatedAt = string | string;

export type StaffUserResponseDtoUpdatedAt = string | string;

export type StaffUserResponseDtoDeletedAt = string | string;

export type StaffUserResponseDtoCreatedAt = string | string;

export interface StaffUserResponseDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: StaffUserResponseDtoCreatedAt;
  deletedAt: StaffUserResponseDtoDeletedAt;
  updatedAt: StaffUserResponseDtoUpdatedAt;
}

export interface UpsertStaffUserResponseDto {
  id: string;
  createdAt: UpsertStaffUserResponseDtoCreatedAt;
  updatedAt: UpsertStaffUserResponseDtoUpdatedAt;
  inactivatedAt?: UpsertStaffUserResponseDtoInactivatedAt;
  externalId: string;
  user: StaffUserResponseDto;
  roles: UpsertStaffUserResponseDtoRolesItem[];
}

export type UpsertStaffUserRequestDtoRolesItem = typeof UpsertStaffUserRequestDtoRolesItem[keyof typeof UpsertStaffUserRequestDtoRolesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpsertStaffUserRequestDtoRolesItem = {
  ADMIN: 'ADMIN',
  TRADER: 'TRADER',
} as const;

export interface UpsertStaffUserRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
  roles: UpsertStaffUserRequestDtoRolesItem[];
}

export interface PaginationEndUserMetadataDto {
  pageSize: number;
  currentCursor: string;
  nextCursor: string;
  totalPages: number;
}

export interface EndUsersDto {
  endUsers: EndUserDto[];
}

export interface PoolColorRecordResponseDto {
  id: string;
  type: string;
  coupon: number;
  value: string;
}

export interface GetEndUserTradeHistoryResponseDto {
  tradeRequests: TradeRequestDto[];
}

export interface ListAssociatedResponseDto {
  associatedId: string;
  firstName: string;
  lastName: string;
}

export type ListEndUserResponseDtoInactivatedAt = string | string;

export type ListEndUserResponseDtoUpdatedAt = string | string;

export type ListEndUserResponseDtoCreatedAt = string | string;

export interface ListEndUserResponseDto {
  id: string;
  createdAt?: ListEndUserResponseDtoCreatedAt;
  updatedAt: ListEndUserResponseDtoUpdatedAt;
  inactivatedAt?: ListEndUserResponseDtoInactivatedAt;
  externalId: string;
}

export type ReadEndUserDtoUpdatedAt = string | string;

export type ReadEndUserDtoDeletedAt = string | string;

export type ReadEndUserDtoCreatedAt = string | string;

export interface ReadEndUserDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: ReadEndUserDtoCreatedAt;
  deletedAt: ReadEndUserDtoDeletedAt;
  updatedAt: ReadEndUserDtoUpdatedAt;
}

export interface ReadEndUserRequestDto {
  id: string;
}

export interface EndUserAnalyticsFirm {
  id: string;
  name: string;
  externalId: string;
}

export type ListFixIntegrationResponseDtoUpdatedAt = string | string;

export type ListFixIntegrationResponseDtoCreatedAt = string | string;

export interface ListFixIntegrationResponseDto {
  id: string;
  createdAt?: ListFixIntegrationResponseDtoCreatedAt;
  updatedAt: ListFixIntegrationResponseDtoUpdatedAt;
  tradeComponentId: string;
}

export interface DeleteFixIntegrationResponseDto {
  id: string;
}

export interface DeleteFixIntegrationRequestDto {
  id: string;
}

export type UpdateFixIntegrationResponseDtoUpdatedAt = string | string;

export type UpdateFixIntegrationResponseDtoCreatedAt = string | string;

export interface UpdateFixIntegrationResponseDto {
  id: string;
  createdAt?: UpdateFixIntegrationResponseDtoCreatedAt;
  updatedAt: UpdateFixIntegrationResponseDtoUpdatedAt;
  tradeComponentId: string;
}

export interface UpdateFixIntegrationRequestDto {
  id: string;
}

export type ReadFixIntegrationResponseDtoUpdatedAt = string | string;

export type ReadFixIntegrationResponseDtoCreatedAt = string | string;

export interface ReadFixIntegrationResponseDto {
  id: string;
  createdAt?: ReadFixIntegrationResponseDtoCreatedAt;
  updatedAt: ReadFixIntegrationResponseDtoUpdatedAt;
  tradeComponentId: string;
}

export interface ReadFixIntegrationRequestDto {
  id: string;
}

export type CreateFixIntegrationResponseDtoUpdatedAt = string | string;

export type CreateFixIntegrationResponseDtoCreatedAt = string | string;

export interface CreateFixIntegrationResponseDto {
  id: string;
  createdAt?: CreateFixIntegrationResponseDtoCreatedAt;
  updatedAt: CreateFixIntegrationResponseDtoUpdatedAt;
  tradeComponentId: string;
}

export interface CreateFixIntegrationRequestDto {
  tradeComponentId: string;
}

export interface EndUserCancelTradeDto {
  tradeRequestId: string;
  endUserId: string;
}

export interface CancelTradeDto {
  tradeRequestId: string;
  traderId: string;
}

export interface CommunicateTradeExecutionDto {
  tradeRequestId: string;
  traderId: string;
}

export interface RejectCounterproposalDto {
  tradeProposalId: string;
  endUserId: string;
}

export interface AcceptCounterproposalDto {
  tradeProposalId: string;
  endUserId: string;
}

export type CounterproposalComponentInputDtoType = typeof CounterproposalComponentInputDtoType[keyof typeof CounterproposalComponentInputDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CounterproposalComponentInputDtoType = {
  BID: 'BID',
  OFFER: 'OFFER',
} as const;

export interface CounterproposalComponentInputDto {
  price: PriceDto;
  type: CounterproposalComponentInputDtoType;
}

export interface CreateCounterproposalDto {
  tradeRequestId: string;
  components: CounterproposalComponentInputDto[];
  traderId: string;
  spread?: PriceDto;
}

export interface AssignTraderDto {
  tradeRequestId: string;
  traderId: string;
}

export type CreateTradeRequestDtoType = typeof CreateTradeRequestDtoType[keyof typeof CreateTradeRequestDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateTradeRequestDtoType = {
  BID: 'BID',
  OFFER: 'OFFER',
  ROLL: 'ROLL',
  SWAP: 'SWAP',
} as const;

export type TradeComponentInputDtoType = typeof TradeComponentInputDtoType[keyof typeof TradeComponentInputDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeComponentInputDtoType = {
  BID: 'BID',
  OFFER: 'OFFER',
} as const;

export interface TradeComponentInputDto {
  productId: string;
  amount: number;
  type: TradeComponentInputDtoType;
}

export interface CreateTradeRequestDto {
  type: CreateTradeRequestDtoType;
  components: TradeComponentInputDto[];
  shares: TradeRequestShareDto[];
  endUserId: string;
}

export type TradeRequestDtoUpdatedAt = string | string;

export type TradeRequestDtoCreatedAt = string | string;

export type TradeRequestDtoType = typeof TradeRequestDtoType[keyof typeof TradeRequestDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeRequestDtoType = {
  BID: 'BID',
  OFFER: 'OFFER',
  ROLL: 'ROLL',
  SWAP: 'SWAP',
} as const;

export type TradeRequestDtoStatus = typeof TradeRequestDtoStatus[keyof typeof TradeRequestDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeRequestDtoStatus = {
  CREATED: 'CREATED',
  ASSIGNED: 'ASSIGNED',
  OFFER_SENT: 'OFFER_SENT',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  OFFER_REJECTED: 'OFFER_REJECTED',
  OFFER_RESENT: 'OFFER_RESENT',
  EXECUTED: 'EXECUTED',
  CANCELED: 'CANCELED',
  TIMED_OUT: 'TIMED_OUT',
} as const;

export type TradeRequestProposalDtoCreatedAt = string | string;

export interface TradeRequestProposalDto {
  id: string;
  shares: TradeRequestShareDto[];
  components: TradeRequestComponentDto[];
  trader?: TraderDto;
  spread?: PriceDto;
  createdAt: TradeRequestProposalDtoCreatedAt;
}

export interface TradeRequestDto {
  id: string;
  status: TradeRequestDtoStatus;
  type: TradeRequestDtoType;
  proposal: TradeRequestProposalDto;
  counterproposal?: TradeRequestProposalDto;
  createdBy: EndUserDto;
  chatTopicId?: string;
  assignedTo?: TraderDto;
  createdAt: TradeRequestDtoCreatedAt;
  updatedAt: TradeRequestDtoUpdatedAt;
  canceledByTrader?: TraderDto;
}

export type TradeRequestShareDtoType = typeof TradeRequestShareDtoType[keyof typeof TradeRequestShareDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeRequestShareDtoType = {
  BID: 'BID',
  OFFER: 'OFFER',
} as const;

export interface TradeShareAssociateUserDto {
  id: string;
  firstName: string;
  lastName: string;
}

export interface TradeShareAssociateDto {
  user: TradeShareAssociateUserDto;
}

export interface TradeRequestShareDto {
  amount: number;
  associatedId: string;
  associate?: TradeShareAssociateDto;
  type: TradeRequestShareDtoType;
}

export type EndUserDtoInactivatedAt = string | string;

export type EndUserDtoUpdatedAt = string | string;

export type EndUserDtoCreatedAt = string | string;

export type EndUserDtoRole = typeof EndUserDtoRole[keyof typeof EndUserDtoRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EndUserDtoRole = {
  ANALYTIC_FIRM: 'ANALYTIC_FIRM',
  ASSOCIATED_USER: 'ASSOCIATED_USER',
  ASSOCIATED_NO_ACCESS: 'ASSOCIATED_NO_ACCESS',
  ASSOCIATED_READ_ONLY: 'ASSOCIATED_READ_ONLY',
} as const;

export interface EndUserDto {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  role: EndUserDtoRole;
  createdAt: EndUserDtoCreatedAt;
  updatedAt: EndUserDtoUpdatedAt;
  inactivatedAt?: EndUserDtoInactivatedAt;
  externalId: string;
  userId: string;
  email: string;
  phoneNumber: string;
  analyticsFirm?: EndUserAnalyticsFirm;
}

export type TradeRequestComponentDtoType = typeof TradeRequestComponentDtoType[keyof typeof TradeRequestComponentDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeRequestComponentDtoType = {
  BID: 'BID',
  OFFER: 'OFFER',
} as const;

export interface TradeRequestComponentDto {
  price?: PriceDto;
  product: ProductDto;
  amount: number;
  type: TradeRequestComponentDtoType;
}

export interface SystemSettingsDto {
  timeoutInSeconds: number;
  lockTradeRequests: boolean;
}

export type ListSystemSettingResponseDtoKey = typeof ListSystemSettingResponseDtoKey[keyof typeof ListSystemSettingResponseDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListSystemSettingResponseDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export type ListSystemSettingResponseDtoUpdatedAt = string | string;

export type ListSystemSettingResponseDtoCreatedAt = string | string;

export interface ListSystemSettingResponseDto {
  id: string;
  createdAt?: ListSystemSettingResponseDtoCreatedAt;
  updatedAt: ListSystemSettingResponseDtoUpdatedAt;
  key: ListSystemSettingResponseDtoKey;
  value: string;
}

export type ReadSystemSettingResponseDtoKey = typeof ReadSystemSettingResponseDtoKey[keyof typeof ReadSystemSettingResponseDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadSystemSettingResponseDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export type ReadSystemSettingResponseDtoUpdatedAt = string | string;

export type ReadSystemSettingResponseDtoCreatedAt = string | string;

export interface ReadSystemSettingResponseDto {
  id: string;
  createdAt?: ReadSystemSettingResponseDtoCreatedAt;
  updatedAt: ReadSystemSettingResponseDtoUpdatedAt;
  key: ReadSystemSettingResponseDtoKey;
  value: string;
}

export interface ReadSystemSettingRequestDto {
  id: string;
}

export type CreateSystemSettingResponseDtoKey = typeof CreateSystemSettingResponseDtoKey[keyof typeof CreateSystemSettingResponseDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSystemSettingResponseDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export type CreateSystemSettingResponseDtoUpdatedAt = string | string;

export type CreateSystemSettingResponseDtoCreatedAt = string | string;

export interface CreateSystemSettingResponseDto {
  id: string;
  createdAt?: CreateSystemSettingResponseDtoCreatedAt;
  updatedAt: CreateSystemSettingResponseDtoUpdatedAt;
  key: CreateSystemSettingResponseDtoKey;
  value: string;
}

export type CreateSystemSettingRequestDtoKey = typeof CreateSystemSettingRequestDtoKey[keyof typeof CreateSystemSettingRequestDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSystemSettingRequestDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export interface CreateSystemSettingRequestDto {
  key: CreateSystemSettingRequestDtoKey;
  value: string;
}

export interface ChangeCredentialDto {
  credential: string;
  newCredential: string;
  confirmNewCredential: string;
}

export interface AuthForgotCredentialDto {
  email: string;
}

export interface AuthSignupConfirmationDto {
  code: string;
  signature?: string;
}

export interface AuthTokens {
  accessToken: string;
  refreshToken?: string;
  idToken?: string;
  twilioAccessToken?: string;
}

export type ListProductCouponItemResponseDtoUpdatedAt = string | string;

export type ListProductCouponItemResponseDtoCreatedAt = string | string;

export interface ListProductCouponItemResponseDto {
  id: string;
  value: number;
  hidden: boolean;
  createdAt: ListProductCouponItemResponseDtoCreatedAt;
  updatedAt: ListProductCouponItemResponseDtoUpdatedAt;
}

export interface PriceDto {
  par: number;
  fraction: number;
}

export interface ErrorDto {
  message: string;
  code: string;
  status: number;
  context?: unknown;
}

export interface ErrorMessageDto {
  error: ErrorDto;
  success: boolean;
}

export interface ApiRequestDto {
  data: Object;
  meta?: Object;
}

export interface PaginationMetadataDto {
  pageSize: number;
  currentCursor: string;
  nextCursor: string;
}

export interface ApiResponseMetadataDto {
  pagination?: PaginationMetadataDto;
}

export interface Object { [key: string]: any }

export type ListTradeRequestEventResponseDtoCreatedAt = string | string;

export interface ListTradeRequestEventResponseDto {
  id: string;
  createdAt?: ListTradeRequestEventResponseDtoCreatedAt;
  tradeRequestId: string;
}

export interface StaffUserJoinTradeRequestConvoDto {
  tradeRequestId: string;
}

export interface NoOpenTradeRequestChatFoundError { [key: string]: any }

export interface ApiMetaDto {
  meta?: Object;
}

export interface GetStaffUserTradeHistoryResponseDto {
  data: TradeRequestsDto;
  meta?: PaginationStaffUserMetadataDto;
}

export interface UpdateAnalyticFirmRequestDto {
  id: string;
  name?: string;
  externalId?: string;
}

export interface DissociateEndUserRequestDto {
  endUserId: string;
}

export interface CreateAnalyticFirmsRequestDto {
  items: AnalyticFirmRecordRequestDto[];
}

export interface CreateAnalyticFirmRequestDto {
  name?: string;
  externalId: string;
}

export interface AnalyticFirmAlreadyExistError { [key: string]: any }

export interface AttachAnalyticFirmRequestDto {
  externalId: string;
  items: AttachAnalyticFirmEndUserRequestDto[];
}

export type AnalyticFirmListResponseDtoUpdatedAt = string | string;

export type AnalyticFirmListResponseDtoCreatedAt = string | string;

export interface AnalyticFirmListResponseDto {
  id: string;
  externalId: string;
  companyName: string;
  name: string;
  createdAt: AnalyticFirmListResponseDtoCreatedAt;
  updatedAt: AnalyticFirmListResponseDtoUpdatedAt;
  endUsers: AnalyticFirmAssociatedEndUserResponseDto[];
}

export interface ListAnalyticFirmsResponseDto {
  items: AnalyticFirmListResponseDto[];
}

export interface CreatePoolColorsRequestDto {
  items: PoolColorRecordRequestDto[];
}

export interface GetTraderInitialStateResponseDto {
  tradeRequests: TradeRequestDto[];
  liveUpdatesTopicId: string;
  globalTopicId: string;
  systemSettings: SystemSettingsDto;
}

export interface GetListStaffUserRequestDto {
  nextCursor?: string;
  limit?: number;
}

export interface GetListStaffUserResponseDto {
  data: StaffUsersDto;
  meta?: PaginationStaffUserMetadataDto;
}

export interface DeleteStaffUserRequestDto {
  id: string;
}

export interface DeleteStaffUserResponseDto {
  id: string;
}

export type ReadStaffUserResponseDtoRolesItem = typeof ReadStaffUserResponseDtoRolesItem[keyof typeof ReadStaffUserResponseDtoRolesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadStaffUserResponseDtoRolesItem = {
  ADMIN: 'ADMIN',
  TRADER: 'TRADER',
} as const;

export type ReadStaffUserResponseDtoInactivatedAt = string | string;

export type ReadStaffUserResponseDtoUpdatedAt = string | string;

export type ReadStaffUserResponseDtoCreatedAt = string | string;

export interface ReadStaffUserResponseDto {
  id: string;
  createdAt: ReadStaffUserResponseDtoCreatedAt;
  updatedAt: ReadStaffUserResponseDtoUpdatedAt;
  inactivatedAt?: ReadStaffUserResponseDtoInactivatedAt;
  roles: ReadStaffUserResponseDtoRolesItem[];
  user?: StaffUserResponseDto;
  externalId: string;
}

export interface TraderDto {
  id: string;
  firstName: string;
  lastName: string;
}

export type ListStaffRoleMappingResponseDtoDeletedAt = string | string;

export type ListStaffRoleMappingResponseDtoUpdatedAt = string | string;

export type ListStaffRoleMappingResponseDtoCreatedAt = string | string;

export interface ListStaffRoleMappingResponseDto {
  id: string;
  createdAt?: ListStaffRoleMappingResponseDtoCreatedAt;
  updatedAt: ListStaffRoleMappingResponseDtoUpdatedAt;
  deletedAt?: ListStaffRoleMappingResponseDtoDeletedAt;
  entitlement: string;
}

export interface DeleteStaffRoleMappingRequestDto {
  id: string;
}

export interface DeleteStaffRoleMappingResponseDto {
  id: string;
}

export type UpdateStaffRoleMappingRequestDtoDeletedAt = string | string;

export interface UpdateStaffRoleMappingRequestDto {
  id: string;
  deletedAt?: UpdateStaffRoleMappingRequestDtoDeletedAt;
  entitlement?: string;
}

export type UpdateStaffRoleMappingResponseDtoDeletedAt = string | string;

export type UpdateStaffRoleMappingResponseDtoUpdatedAt = string | string;

export type UpdateStaffRoleMappingResponseDtoCreatedAt = string | string;

export interface UpdateStaffRoleMappingResponseDto {
  id: string;
  createdAt?: UpdateStaffRoleMappingResponseDtoCreatedAt;
  updatedAt: UpdateStaffRoleMappingResponseDtoUpdatedAt;
  deletedAt?: UpdateStaffRoleMappingResponseDtoDeletedAt;
  entitlement: string;
}

export type ReadStaffRoleMappingResponseDtoDeletedAt = string | string;

export type ReadStaffRoleMappingResponseDtoUpdatedAt = string | string;

export type ReadStaffRoleMappingResponseDtoCreatedAt = string | string;

export interface ReadStaffRoleMappingResponseDto {
  id: string;
  createdAt?: ReadStaffRoleMappingResponseDtoCreatedAt;
  updatedAt: ReadStaffRoleMappingResponseDtoUpdatedAt;
  deletedAt?: ReadStaffRoleMappingResponseDtoDeletedAt;
  entitlement: string;
}

export type CreateStaffRoleMappingRequestDtoDeletedAt = string | string;

export interface CreateStaffRoleMappingRequestDto {
  deletedAt?: CreateStaffRoleMappingRequestDtoDeletedAt;
  entitlement: string;
}

export type CreateStaffRoleMappingResponseDtoDeletedAt = string | string;

export type CreateStaffRoleMappingResponseDtoUpdatedAt = string | string;

export type CreateStaffRoleMappingResponseDtoCreatedAt = string | string;

export interface CreateStaffRoleMappingResponseDto {
  id: string;
  createdAt?: CreateStaffRoleMappingResponseDtoCreatedAt;
  updatedAt: CreateStaffRoleMappingResponseDtoUpdatedAt;
  deletedAt?: CreateStaffRoleMappingResponseDtoDeletedAt;
  entitlement: string;
}

export interface MicrosoftAuthRequestDto {
  code: string;
  client_info: string;
  state: string;
  session_state: string;
}

export interface MicrosoftAuthResponseDto { [key: string]: any }

export interface InternalServerErrorException { [key: string]: any }

export interface EndUserNotFoundByUserIdError { [key: string]: any }

export interface EndUserAccessTokenResponseDto {
  twilioAccessToken: string;
}

export interface SouthStreetOtpVerifyCodeRequestDto {
  code: string;
}

export interface OTPFailedToVerifyCodeError { [key: string]: any }

export interface SouthStreetSendOtpRequestDto {
  identifier: string;
  credential: string;
}

export interface OutBusinessWorkingHoursError { [key: string]: any }

export interface AuthTokensResponseDto {
  tokens: AuthTokens;
}

export interface ResetCredentialDto {
  code: string;
  signature?: string;
  credential: string;
  confirmCredential: string;
}

export interface IncorrectSetupError { [key: string]: any }

export interface SetPasswordFailedError { [key: string]: any }

export interface EndUserAlreadyExistError { [key: string]: any }

export interface InvalidValidationSourceError { [key: string]: any }

export interface OTPFailedToSendVerificationCodeError { [key: string]: any }

export interface ResetPasswordResponseDto {
  email: string;
  tokens: AuthTokens;
}

export interface ForgotEndUserPasswordRequestDto {
  id: string;
}

export interface ForgotPasswordRequestDto {
  email: string;
}

export interface SendEndUserInviteRequestDto {
  companyName: string;
  externalId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface EmailAlreadyExistError { [key: string]: any }

export interface PhoneNumberAlreadyExistError { [key: string]: any }

export interface ListSystemSettingsResponseDto {
  systemSettings: ListSystemSettingResponseDto[];
}

export interface DeleteSystemSettingRequestDto {
  id: string;
}

export interface DeleteSystemSettingResponseDto {
  id: string;
}

export type UpsertSystemSettingRequestDtoKey = typeof UpsertSystemSettingRequestDtoKey[keyof typeof UpsertSystemSettingRequestDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpsertSystemSettingRequestDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export interface UpsertSystemSettingRequestDto {
  key: UpsertSystemSettingRequestDtoKey;
  value: string;
}

export type UpsertSystemSettingResponseDtoKey = typeof UpsertSystemSettingResponseDtoKey[keyof typeof UpsertSystemSettingResponseDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpsertSystemSettingResponseDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export type UpsertSystemSettingResponseDtoUpdatedAt = string | string;

export type UpsertSystemSettingResponseDtoCreatedAt = string | string;

export interface UpsertSystemSettingResponseDto {
  id: string;
  createdAt?: UpsertSystemSettingResponseDtoCreatedAt;
  updatedAt: UpsertSystemSettingResponseDtoUpdatedAt;
  key: UpsertSystemSettingResponseDtoKey;
  value: string;
}

export type UpdateSystemSettingResponseDtoKey = typeof UpdateSystemSettingResponseDtoKey[keyof typeof UpdateSystemSettingResponseDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateSystemSettingResponseDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export type UpdateSystemSettingResponseDtoUpdatedAt = string | string;

export type UpdateSystemSettingResponseDtoCreatedAt = string | string;

export interface UpdateSystemSettingResponseDto {
  id: string;
  createdAt?: UpdateSystemSettingResponseDtoCreatedAt;
  updatedAt: UpdateSystemSettingResponseDtoUpdatedAt;
  key: UpdateSystemSettingResponseDtoKey;
  value: string;
}

export type UpdateSystemSettingRequestDtoKey = typeof UpdateSystemSettingRequestDtoKey[keyof typeof UpdateSystemSettingRequestDtoKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateSystemSettingRequestDtoKey = {
  TIMEOUT_SECONDS: 'TIMEOUT_SECONDS',
  MULTIPLE_TRADE_REQUESTS_ALLOWED: 'MULTIPLE_TRADE_REQUESTS_ALLOWED',
  LOCK_TRADE_REQUESTS: 'LOCK_TRADE_REQUESTS',
  BUSINESS_WORKING_HOURS: 'BUSINESS_WORKING_HOURS',
  AVAILABLE_PRODUCT_GROUPS: 'AVAILABLE_PRODUCT_GROUPS',
} as const;

export interface UpdateSystemSettingRequestDto {
  id: string;
  key?: UpdateSystemSettingRequestDtoKey;
  value?: string;
}

export interface ListProductCouponsResponseDto {
  items: ListProductCouponItemResponseDto[];
}

export interface UpdateProductCouponRequestDto {
  value?: number;
  hidden?: boolean;
}

export interface ProductCouponNotFoundError { [key: string]: any }

export interface DefaultDto {
  result: boolean;
}

export interface CreateProductCouponRequestDto {
  value: number;
  hidden: boolean;
}

export interface ProductCouponValueCollisionError { [key: string]: any }

export type CreateProductCouponResponseDtoUpdatedAt = string | string;

export type CreateProductCouponResponseDtoCreatedAt = string | string;

export interface CreateProductCouponResponseDto {
  id: string;
  value: number;
  hidden: boolean;
  createdAt: CreateProductCouponResponseDtoCreatedAt;
  updatedAt: CreateProductCouponResponseDtoUpdatedAt;
}

export type ProductDtoMaturityDate = string | string;

export type ProductDtoSettlementDate = string | string;

export interface ProductDto {
  id: string;
  name: string;
  cusip: string;
  coupon: number;
  askPrice?: PriceDto;
  bidPrice?: PriceDto;
  askSpread?: PriceDto;
  bidSpread?: PriceDto;
  month: number;
  rollMonth?: number;
  leg1Product?: ProductDto;
  leg2Product?: ProductDto;
  settlementDate: ProductDtoSettlementDate;
  maturityDate: ProductDtoMaturityDate;
}

export interface ListProductResponseDto {
  id: string;
  name: string;
  products: ProductDto[];
}

export type ListPoolColorResponseDtoUpdatedAt = string | string;

export interface ListPoolColorResponseDto {
  updatedAt: ListPoolColorResponseDtoUpdatedAt;
  items: PoolColorRecordResponseDto[];
}

export interface TradeRequestsDto {
  tradeRequests: TradeRequestDto[];
}

export interface EndUserNotAssociatedError { [key: string]: any }

export interface EndUserNotFoundError { [key: string]: any }

export interface GetEndUserAnalyticFirmAssociateResponseDto {
  associates: ListAssociatedResponseDto[];
}

export interface GetEndUserInitialStateResponseDto {
  products: ListProductResponseDto[];
  tradeRequest?: TradeRequestDto;
  liveUpdatesTopicId: string;
  globalTopicId: string;
  systemSettings: SystemSettingsDto;
}

export interface GetListEndUserRequestDto {
  nextCursor?: string;
  limit?: number;
}

export interface GetListEndUserResponseDto {
  data: EndUsersDto;
  meta?: PaginationEndUserMetadataDto;
}

export interface DeleteEndUserRequestDto {
  id: string;
}

export interface DeleteEndUserResponseDto {
  id: string;
}

export type UpdateEndUserRequestDtoRole = typeof UpdateEndUserRequestDtoRole[keyof typeof UpdateEndUserRequestDtoRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateEndUserRequestDtoRole = {
  ANALYTIC_FIRM: 'ANALYTIC_FIRM',
  ASSOCIATED_USER: 'ASSOCIATED_USER',
  ASSOCIATED_NO_ACCESS: 'ASSOCIATED_NO_ACCESS',
  ASSOCIATED_READ_ONLY: 'ASSOCIATED_READ_ONLY',
} as const;

export interface UpdateEndUserRequestDto {
  id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  companyName?: string;
  role?: UpdateEndUserRequestDtoRole;
  analyticsFirmId?: string;
  email?: string;
}

export type UpdateEndUserResponseDtoInactivatedAt = string | string;

export type UpdateEndUserResponseDtoUpdatedAt = string | string;

export type UpdateEndUserResponseDtoCreatedAt = string | string;

export interface UpdateEndUserResponseDto {
  id: string;
  createdAt?: UpdateEndUserResponseDtoCreatedAt;
  updatedAt: UpdateEndUserResponseDtoUpdatedAt;
  inactivatedAt?: UpdateEndUserResponseDtoInactivatedAt;
  role?: string;
  analyticsFirmId?: string;
  externalId: string;
}

export type ReadEndUserResponseDtoTermsAcceptedAt = string | string;

export type ReadEndUserResponseDtoRole = typeof ReadEndUserResponseDtoRole[keyof typeof ReadEndUserResponseDtoRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadEndUserResponseDtoRole = {
  ANALYTIC_FIRM: 'ANALYTIC_FIRM',
  ASSOCIATED_USER: 'ASSOCIATED_USER',
  ASSOCIATED_NO_ACCESS: 'ASSOCIATED_NO_ACCESS',
  ASSOCIATED_READ_ONLY: 'ASSOCIATED_READ_ONLY',
} as const;

export type ReadEndUserResponseDtoInactivatedAt = string | string;

export type ReadEndUserResponseDtoUpdatedAt = string | string;

export type ReadEndUserResponseDtoCreatedAt = string | string;

export interface ReadEndUserResponseDto {
  id: string;
  createdAt?: ReadEndUserResponseDtoCreatedAt;
  companyName: string;
  updatedAt: ReadEndUserResponseDtoUpdatedAt;
  inactivatedAt?: ReadEndUserResponseDtoInactivatedAt;
  user?: ReadEndUserDto;
  role?: ReadEndUserResponseDtoRole;
  externalId: string;
  termsAcceptedAt?: ReadEndUserResponseDtoTermsAcceptedAt;
  analyticsFirm?: EndUserAnalyticsFirm;
}

export type EndUserSelfResponseTermsAcceptedAt = string | string;

export type EndUserSelfResponseRole = typeof EndUserSelfResponseRole[keyof typeof EndUserSelfResponseRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EndUserSelfResponseRole = {
  ANALYTIC_FIRM: 'ANALYTIC_FIRM',
  ASSOCIATED_USER: 'ASSOCIATED_USER',
  ASSOCIATED_NO_ACCESS: 'ASSOCIATED_NO_ACCESS',
  ASSOCIATED_READ_ONLY: 'ASSOCIATED_READ_ONLY',
} as const;

export interface EndUserSelfResponse {
  phoneNumber: string | null;
  id: string;
  name: string;
  email: string;
  companyName: string;
  role?: EndUserSelfResponseRole;
  termsAcceptedAt?: EndUserSelfResponseTermsAcceptedAt;
  analyticsFirm?: EndUserAnalyticsFirm;
}

export interface MinimumAppVersionResponseDto {
  appVersion: string;
}

export interface RedirectToSetPasswordDeepLinkRequestDto {
  code: string;
  signature: string;
}

export interface ApiError { [key: string]: any }

export interface ApiErrorResponseDto {
  statusCode: number;
  error: ErrorDto;
}

export type GetAnalyticFirmAssociates500 = ApiErrorResponseDto & GetAnalyticFirmAssociates500AllOf & GetAnalyticFirmAssociates500AllOfTwo;

export interface Function { [key: string]: any }

export interface ApiResponseDto {
  data: Object;
  meta?: Object;
}



type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getData = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<void>(
      {url: `/`, method: 'get', signal
    },
      options);
    }
  

export const getGetDataQueryKey = () => [`/`];

    
export type GetDataQueryResult = NonNullable<Awaited<ReturnType<typeof getData>>>
export type GetDataQueryError = unknown

export const useGetData = <TData = Awaited<ReturnType<typeof getData>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getData>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getData>>> = ({ signal }) => getData(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getData>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Redirects the user to the set password deep link.
 */
export const redirectToResetPasswordDeepLink = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<RedirectToResetPasswordDeepLink200>(
      {url: `/set-password-deep-link`, method: 'get', signal
    },
      options);
    }
  

export const getRedirectToResetPasswordDeepLinkQueryKey = () => [`/set-password-deep-link`];

    
export type RedirectToResetPasswordDeepLinkQueryResult = NonNullable<Awaited<ReturnType<typeof redirectToResetPasswordDeepLink>>>
export type RedirectToResetPasswordDeepLinkQueryError = RedirectToResetPasswordDeepLink500

export const useRedirectToResetPasswordDeepLink = <TData = Awaited<ReturnType<typeof redirectToResetPasswordDeepLink>>, TError = RedirectToResetPasswordDeepLink500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof redirectToResetPasswordDeepLink>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRedirectToResetPasswordDeepLinkQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof redirectToResetPasswordDeepLink>>> = ({ signal }) => redirectToResetPasswordDeepLink(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof redirectToResetPasswordDeepLink>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Redirects the minimum app version supported.
 */
export const getMinimumAppVersion = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetMinimumAppVersion200>(
      {url: `/min-app-version`, method: 'get', signal
    },
      options);
    }
  

export const getGetMinimumAppVersionQueryKey = () => [`/min-app-version`];

    
export type GetMinimumAppVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getMinimumAppVersion>>>
export type GetMinimumAppVersionQueryError = GetMinimumAppVersion500

export const useGetMinimumAppVersion = <TData = Awaited<ReturnType<typeof getMinimumAppVersion>>, TError = GetMinimumAppVersion500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMinimumAppVersion>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMinimumAppVersionQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMinimumAppVersion>>> = ({ signal }) => getMinimumAppVersion(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getMinimumAppVersion>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns logged user id
 */
export const getCurrentEndUser = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetCurrentEndUser200>(
      {url: `/end-users/me`, method: 'get', signal
    },
      options);
    }
  

export const getGetCurrentEndUserQueryKey = () => [`/end-users/me`];

    
export type GetCurrentEndUserQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentEndUser>>>
export type GetCurrentEndUserQueryError = GetCurrentEndUser500

export const useGetCurrentEndUser = <TData = Awaited<ReturnType<typeof getCurrentEndUser>>, TError = GetCurrentEndUser500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCurrentEndUser>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentEndUserQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentEndUser>>> = ({ signal }) => getCurrentEndUser(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCurrentEndUser>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Read end-user
 */
export const readEndUser = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ReadEndUser200>(
      {url: `/end-users/read`, method: 'get', signal
    },
      options);
    }
  

export const getReadEndUserQueryKey = () => [`/end-users/read`];

    
export type ReadEndUserQueryResult = NonNullable<Awaited<ReturnType<typeof readEndUser>>>
export type ReadEndUserQueryError = ReadEndUser500

export const useReadEndUser = <TData = Awaited<ReturnType<typeof readEndUser>>, TError = ReadEndUser500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readEndUser>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadEndUserQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof readEndUser>>> = ({ signal }) => readEndUser(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof readEndUser>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Update EndUser
 */
export const updateEndUser = (
    updateEndUserRequestDto: UpdateEndUserRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<UpdateEndUser200>(
      {url: `/end-users/update`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateEndUserRequestDto
    },
      options);
    }
  


    export type UpdateEndUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateEndUser>>>
    export type UpdateEndUserMutationBody = UpdateEndUserRequestDto
    export type UpdateEndUserMutationError = UpdateEndUser500

    export const useUpdateEndUser = <TError = UpdateEndUser500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEndUser>>, TError,{data: UpdateEndUserRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEndUser>>, {data: UpdateEndUserRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  updateEndUser(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateEndUser>>, TError, {data: UpdateEndUserRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Delete EndUser
 */
export const deleteEndUser = (
    deleteEndUserRequestDto: DeleteEndUserRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<DeleteEndUser200>(
      {url: `/end-users/delete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deleteEndUserRequestDto
    },
      options);
    }
  


    export type DeleteEndUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEndUser>>>
    export type DeleteEndUserMutationBody = DeleteEndUserRequestDto
    export type DeleteEndUserMutationError = DeleteEndUser500

    export const useDeleteEndUser = <TError = DeleteEndUser500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEndUser>>, TError,{data: DeleteEndUserRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEndUser>>, {data: DeleteEndUserRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  deleteEndUser(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteEndUser>>, TError, {data: DeleteEndUserRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List all end-users
 */
export const listEndUsers = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetListEndUserResponseDto>(
      {url: `/end-users/list`, method: 'get', signal
    },
      options);
    }
  

export const getListEndUsersQueryKey = () => [`/end-users/list`];

    
export type ListEndUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listEndUsers>>>
export type ListEndUsersQueryError = ListEndUsers500

export const useListEndUsers = <TData = Awaited<ReturnType<typeof listEndUsers>>, TError = ListEndUsers500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEndUsers>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEndUsersQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listEndUsers>>> = ({ signal }) => listEndUsers(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listEndUsers>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the initial state for end-users
 */
export const getInitialEndUserState = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetInitialEndUserState200>(
      {url: `/end-users/initial-state`, method: 'get', signal
    },
      options);
    }
  

export const getGetInitialEndUserStateQueryKey = () => [`/end-users/initial-state`];

    
export type GetInitialEndUserStateQueryResult = NonNullable<Awaited<ReturnType<typeof getInitialEndUserState>>>
export type GetInitialEndUserStateQueryError = GetInitialEndUserState500

export const useGetInitialEndUserState = <TData = Awaited<ReturnType<typeof getInitialEndUserState>>, TError = GetInitialEndUserState500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInitialEndUserState>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInitialEndUserStateQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInitialEndUserState>>> = ({ signal }) => getInitialEndUserState(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getInitialEndUserState>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the analytic firm associates for end-users
 */
export const getAnalyticFirmAssociates = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetAnalyticFirmAssociates200>(
      {url: `/end-users/analytic-firm-associates`, method: 'get', signal
    },
      options);
    }
  

export const getGetAnalyticFirmAssociatesQueryKey = () => [`/end-users/analytic-firm-associates`];

    
export type GetAnalyticFirmAssociatesQueryResult = NonNullable<Awaited<ReturnType<typeof getAnalyticFirmAssociates>>>
export type GetAnalyticFirmAssociatesQueryError = GetAnalyticFirmAssociates500

export const useGetAnalyticFirmAssociates = <TData = Awaited<ReturnType<typeof getAnalyticFirmAssociates>>, TError = GetAnalyticFirmAssociates500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAnalyticFirmAssociates>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAnalyticFirmAssociatesQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticFirmAssociates>>> = ({ signal }) => getAnalyticFirmAssociates(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAnalyticFirmAssociates>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the end-user trade history
 */
export const getEndUserTradeHistory = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetEndUserTradeHistory200>(
      {url: `/end-users/trade-history`, method: 'get', signal
    },
      options);
    }
  

export const getGetEndUserTradeHistoryQueryKey = () => [`/end-users/trade-history`];

    
export type GetEndUserTradeHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getEndUserTradeHistory>>>
export type GetEndUserTradeHistoryQueryError = GetEndUserTradeHistory500

export const useGetEndUserTradeHistory = <TData = Awaited<ReturnType<typeof getEndUserTradeHistory>>, TError = GetEndUserTradeHistory500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEndUserTradeHistory>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEndUserTradeHistoryQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEndUserTradeHistory>>> = ({ signal }) => getEndUserTradeHistory(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getEndUserTradeHistory>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * List pool colors
 */
export const listPoolColors = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ListPoolColors200>(
      {url: `/end-users/pool-colors`, method: 'get', signal
    },
      options);
    }
  

export const getListPoolColorsQueryKey = () => [`/end-users/pool-colors`];

    
export type ListPoolColorsQueryResult = NonNullable<Awaited<ReturnType<typeof listPoolColors>>>
export type ListPoolColorsQueryError = ListPoolColors500

export const useListPoolColors = <TData = Awaited<ReturnType<typeof listPoolColors>>, TError = ListPoolColors500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listPoolColors>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPoolColorsQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPoolColors>>> = ({ signal }) => listPoolColors(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listPoolColors>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the product list for end-users
 */
export const getProductList = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetProductList200>(
      {url: `/list`, method: 'get', signal
    },
      options);
    }
  

export const getGetProductListQueryKey = () => [`/list`];

    
export type GetProductListQueryResult = NonNullable<Awaited<ReturnType<typeof getProductList>>>
export type GetProductListQueryError = GetProductList500

export const useGetProductList = <TData = Awaited<ReturnType<typeof getProductList>>, TError = GetProductList500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductList>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductListQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductList>>> = ({ signal }) => getProductList(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getProductList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * List Product
 */
export const listProduct = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ProductDto[]>(
      {url: `/list-product`, method: 'get', signal
    },
      options);
    }
  

export const getListProductQueryKey = () => [`/list-product`];

    
export type ListProductQueryResult = NonNullable<Awaited<ReturnType<typeof listProduct>>>
export type ListProductQueryError = unknown

export const useListProduct = <TData = Awaited<ReturnType<typeof listProduct>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProduct>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProductQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProduct>>> = ({ signal }) => listProduct(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listProduct>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create a new product's coupon.
 */
export const createCoupon = (
    createProductCouponRequestDto: CreateProductCouponRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<CreateCoupon200>(
      {url: `/create-coupon`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createProductCouponRequestDto
    },
      options);
    }
  


    export type CreateCouponMutationResult = NonNullable<Awaited<ReturnType<typeof createCoupon>>>
    export type CreateCouponMutationBody = CreateProductCouponRequestDto
    export type CreateCouponMutationError = CreateCoupon400 | CreateCoupon500

    export const useCreateCoupon = <TError = CreateCoupon400 | CreateCoupon500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCoupon>>, TError,{data: CreateProductCouponRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCoupon>>, {data: CreateProductCouponRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  createCoupon(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createCoupon>>, TError, {data: CreateProductCouponRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Update the given product's coupon.
 */
export const updateCoupon = (
    id: string,
    updateProductCouponRequestDto: UpdateProductCouponRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<UpdateCoupon200>(
      {url: `/update-coupon/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateProductCouponRequestDto
    },
      options);
    }
  


    export type UpdateCouponMutationResult = NonNullable<Awaited<ReturnType<typeof updateCoupon>>>
    export type UpdateCouponMutationBody = UpdateProductCouponRequestDto
    export type UpdateCouponMutationError = UpdateCoupon404 | UpdateCoupon500

    export const useUpdateCoupon = <TError = UpdateCoupon404 | UpdateCoupon500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCoupon>>, TError,{id: string;data: UpdateProductCouponRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCoupon>>, {id: string;data: UpdateProductCouponRequestDto}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCoupon(id,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateCoupon>>, TError, {id: string;data: UpdateProductCouponRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List all product's coupons.
 */
export const listCoupons = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ListCoupons200>(
      {url: `/list-coupons`, method: 'get', signal
    },
      options);
    }
  

export const getListCouponsQueryKey = () => [`/list-coupons`];

    
export type ListCouponsQueryResult = NonNullable<Awaited<ReturnType<typeof listCoupons>>>
export type ListCouponsQueryError = ListCoupons500

export const useListCoupons = <TData = Awaited<ReturnType<typeof listCoupons>>, TError = ListCoupons500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCoupons>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCouponsQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCoupons>>> = ({ signal }) => listCoupons(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listCoupons>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Update SystemSetting
 */
export const updateSystemSetting = (
    updateSystemSettingRequestDto: UpdateSystemSettingRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<UpdateSystemSettingResponseDto>(
      {url: `/update-systemSetting`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateSystemSettingRequestDto
    },
      options);
    }
  


    export type UpdateSystemSettingMutationResult = NonNullable<Awaited<ReturnType<typeof updateSystemSetting>>>
    export type UpdateSystemSettingMutationBody = UpdateSystemSettingRequestDto
    export type UpdateSystemSettingMutationError = unknown

    export const useUpdateSystemSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSystemSetting>>, TError,{data: UpdateSystemSettingRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSystemSetting>>, {data: UpdateSystemSettingRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  updateSystemSetting(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateSystemSetting>>, TError, {data: UpdateSystemSettingRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Upsert system setting key and value
 */
export const upsertSystemSetting = (
    upsertSystemSettingRequestDto: UpsertSystemSettingRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<UpsertSystemSetting200>(
      {url: `/upsert-systemSetting`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: upsertSystemSettingRequestDto
    },
      options);
    }
  


    export type UpsertSystemSettingMutationResult = NonNullable<Awaited<ReturnType<typeof upsertSystemSetting>>>
    export type UpsertSystemSettingMutationBody = UpsertSystemSettingRequestDto
    export type UpsertSystemSettingMutationError = UpsertSystemSetting500

    export const useUpsertSystemSetting = <TError = UpsertSystemSetting500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof upsertSystemSetting>>, TError,{data: UpsertSystemSettingRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof upsertSystemSetting>>, {data: UpsertSystemSettingRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  upsertSystemSetting(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof upsertSystemSetting>>, TError, {data: UpsertSystemSettingRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Delete SystemSetting
 */
export const deleteSystemSetting = (
    deleteSystemSettingRequestDto: DeleteSystemSettingRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<DeleteSystemSetting200>(
      {url: `/delete-systemSetting`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deleteSystemSettingRequestDto
    },
      options);
    }
  


    export type DeleteSystemSettingMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSystemSetting>>>
    export type DeleteSystemSettingMutationBody = DeleteSystemSettingRequestDto
    export type DeleteSystemSettingMutationError = DeleteSystemSetting500

    export const useDeleteSystemSetting = <TError = DeleteSystemSetting500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSystemSetting>>, TError,{data: DeleteSystemSettingRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSystemSetting>>, {data: DeleteSystemSettingRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  deleteSystemSetting(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteSystemSetting>>, TError, {data: DeleteSystemSettingRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List all system settings
 */
export const listSystemSetting = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ListSystemSetting200>(
      {url: `/list-systemSetting`, method: 'get', signal
    },
      options);
    }
  

export const getListSystemSettingQueryKey = () => [`/list-systemSetting`];

    
export type ListSystemSettingQueryResult = NonNullable<Awaited<ReturnType<typeof listSystemSetting>>>
export type ListSystemSettingQueryError = ListSystemSetting500

export const useListSystemSetting = <TData = Awaited<ReturnType<typeof listSystemSetting>>, TError = ListSystemSetting500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listSystemSetting>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSystemSettingQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSystemSetting>>> = ({ signal }) => listSystemSetting(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listSystemSetting>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const healthCheck = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<void>(
      {url: `/health-check`, method: 'get', signal
    },
      options);
    }
  

export const getHealthCheckQueryKey = () => [`/health-check`];

    
export type HealthCheckQueryResult = NonNullable<Awaited<ReturnType<typeof healthCheck>>>
export type HealthCheckQueryError = unknown

export const useHealthCheck = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthCheckQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({ signal }) => healthCheck(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof healthCheck>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Dispatches an invitation email to end-user
 */
export const sendInvite = (
    sendEndUserInviteRequestDto: SendEndUserInviteRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<SendInvite200>(
      {url: `/auth/send-end-user-invite`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sendEndUserInviteRequestDto
    },
      options);
    }
  


    export type SendInviteMutationResult = NonNullable<Awaited<ReturnType<typeof sendInvite>>>
    export type SendInviteMutationBody = SendEndUserInviteRequestDto
    export type SendInviteMutationError = SendInvite400 | SendInvite500

    export const useSendInvite = <TError = SendInvite400 | SendInvite500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendInvite>>, TError,{data: SendEndUserInviteRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendInvite>>, {data: SendEndUserInviteRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  sendInvite(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof sendInvite>>, TError, {data: SendEndUserInviteRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Dispatches an email to end-user, allowing them to reset their password
 */
export const forgotPassword = (
    forgotPasswordRequestDto: ForgotPasswordRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<ForgotPassword200>(
      {url: `/auth/forgot-password`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: forgotPasswordRequestDto
    },
      options);
    }
  


    export type ForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof forgotPassword>>>
    export type ForgotPasswordMutationBody = ForgotPasswordRequestDto
    export type ForgotPasswordMutationError = ForgotPassword500

    export const useForgotPassword = <TError = ForgotPassword500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof forgotPassword>>, {data: ForgotPasswordRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  forgotPassword(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof forgotPassword>>, TError, {data: ForgotPasswordRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Dispatches a reset password email on behalf of end-users
 */
export const resetEndUserPassword = (
    forgotEndUserPasswordRequestDto: ForgotEndUserPasswordRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<ResetEndUserPassword200>(
      {url: `/auth/forgot-end-user-password`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: forgotEndUserPasswordRequestDto
    },
      options);
    }
  


    export type ResetEndUserPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetEndUserPassword>>>
    export type ResetEndUserPasswordMutationBody = ForgotEndUserPasswordRequestDto
    export type ResetEndUserPasswordMutationError = ResetEndUserPassword500

    export const useResetEndUserPassword = <TError = ResetEndUserPassword500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetEndUserPassword>>, TError,{data: ForgotEndUserPasswordRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetEndUserPassword>>, {data: ForgotEndUserPasswordRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  resetEndUserPassword(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof resetEndUserPassword>>, TError, {data: ForgotEndUserPasswordRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Set the user password and sends an OTP
 */
export const setPassword = (
    resetCredentialDto: ResetCredentialDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<SetPassword200>(
      {url: `/auth/set-password`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: resetCredentialDto
    },
      options);
    }
  


    export type SetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof setPassword>>>
    export type SetPasswordMutationBody = ResetCredentialDto
    export type SetPasswordMutationError = SetPassword400 | SetPassword500

    export const useSetPassword = <TError = SetPassword400 | SetPassword500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setPassword>>, TError,{data: ResetCredentialDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setPassword>>, {data: ResetCredentialDto}> = (props) => {
          const {data} = props ?? {};

          return  setPassword(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setPassword>>, TError, {data: ResetCredentialDto}, TContext>(mutationFn, mutationOptions);
    }
    
export const sendOtp = (
    southStreetSendOtpRequestDto: SouthStreetSendOtpRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<SendOtp200>(
      {url: `/auth/send-otp`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: southStreetSendOtpRequestDto
    },
      options);
    }
  


    export type SendOtpMutationResult = NonNullable<Awaited<ReturnType<typeof sendOtp>>>
    export type SendOtpMutationBody = SouthStreetSendOtpRequestDto
    export type SendOtpMutationError = SendOtp400 | SendOtp401 | SendOtp500

    export const useSendOtp = <TError = SendOtp400 | SendOtp401 | SendOtp500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendOtp>>, TError,{data: SouthStreetSendOtpRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendOtp>>, {data: SouthStreetSendOtpRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  sendOtp(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof sendOtp>>, TError, {data: SouthStreetSendOtpRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
export const verifyOtp = (
    southStreetOtpVerifyCodeRequestDto: SouthStreetOtpVerifyCodeRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<VerifyOtp200>(
      {url: `/auth/verify-otp`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: southStreetOtpVerifyCodeRequestDto
    },
      options);
    }
  


    export type VerifyOtpMutationResult = NonNullable<Awaited<ReturnType<typeof verifyOtp>>>
    export type VerifyOtpMutationBody = SouthStreetOtpVerifyCodeRequestDto
    export type VerifyOtpMutationError = VerifyOtp400 | VerifyOtp401 | VerifyOtp500

    export const useVerifyOtp = <TError = VerifyOtp400 | VerifyOtp401 | VerifyOtp500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyOtp>>, TError,{data: SouthStreetOtpVerifyCodeRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyOtp>>, {data: SouthStreetOtpVerifyCodeRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  verifyOtp(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof verifyOtp>>, TError, {data: SouthStreetOtpVerifyCodeRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Returns a Twilio access token for the end-user
 */
export const endUserAccessToken = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<EndUserAccessToken200>(
      {url: `/auth/end-user-access-token`, method: 'get', signal
    },
      options);
    }
  

export const getEndUserAccessTokenQueryKey = () => [`/auth/end-user-access-token`];

    
export type EndUserAccessTokenQueryResult = NonNullable<Awaited<ReturnType<typeof endUserAccessToken>>>
export type EndUserAccessTokenQueryError = EndUserAccessToken401 | EndUserAccessToken500

export const useEndUserAccessToken = <TData = Awaited<ReturnType<typeof endUserAccessToken>>, TError = EndUserAccessToken401 | EndUserAccessToken500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof endUserAccessToken>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEndUserAccessTokenQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof endUserAccessToken>>> = ({ signal }) => endUserAccessToken(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof endUserAccessToken>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns a Twilio access token for the trader
 */
export const traderAccessToken = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<TraderAccessToken200>(
      {url: `/auth/trader-access-token`, method: 'get', signal
    },
      options);
    }
  

export const getTraderAccessTokenQueryKey = () => [`/auth/trader-access-token`];

    
export type TraderAccessTokenQueryResult = NonNullable<Awaited<ReturnType<typeof traderAccessToken>>>
export type TraderAccessTokenQueryError = TraderAccessToken500

export const useTraderAccessToken = <TData = Awaited<ReturnType<typeof traderAccessToken>>, TError = TraderAccessToken500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof traderAccessToken>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTraderAccessTokenQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof traderAccessToken>>> = ({ signal }) => traderAccessToken(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof traderAccessToken>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const refreshToken = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<RefreshToken200>(
      {url: `/auth/refresh`, method: 'post'
    },
      options);
    }
  


    export type RefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshToken>>>
    
    export type RefreshTokenMutationError = RefreshToken401 | RefreshToken404 | RefreshToken500

    export const useRefreshToken = <TError = RefreshToken401 | RefreshToken404 | RefreshToken500,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshToken>>, TVariables> = () => {
          

          return  refreshToken(requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof refreshToken>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Returns a Microsoft OAuth redirect URL. Optional: ("redirect_to" query param)
 */
export const microsoftSignIn = (
    params: MicrosoftSignInParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<MicrosoftSignIn200>(
      {url: `/auth/microsoft/signin`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getMicrosoftSignInQueryKey = (params: MicrosoftSignInParams,) => [`/auth/microsoft/signin`, ...(params ? [params]: [])];

    
export type MicrosoftSignInQueryResult = NonNullable<Awaited<ReturnType<typeof microsoftSignIn>>>
export type MicrosoftSignInQueryError = MicrosoftSignIn500

export const useMicrosoftSignIn = <TData = Awaited<ReturnType<typeof microsoftSignIn>>, TError = MicrosoftSignIn500>(
 params: MicrosoftSignInParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof microsoftSignIn>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMicrosoftSignInQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof microsoftSignIn>>> = ({ signal }) => microsoftSignIn(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof microsoftSignIn>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const microsoftRedirect = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<MicrosoftRedirect200>(
      {url: `/auth/microsoft/redirect`, method: 'get', signal
    },
      options);
    }
  

export const getMicrosoftRedirectQueryKey = () => [`/auth/microsoft/redirect`];

    
export type MicrosoftRedirectQueryResult = NonNullable<Awaited<ReturnType<typeof microsoftRedirect>>>
export type MicrosoftRedirectQueryError = MicrosoftRedirect500

export const useMicrosoftRedirect = <TData = Awaited<ReturnType<typeof microsoftRedirect>>, TError = MicrosoftRedirect500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof microsoftRedirect>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMicrosoftRedirectQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof microsoftRedirect>>> = ({ signal }) => microsoftRedirect(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof microsoftRedirect>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns a end-user session tokens (only for lower stage, for back-end testing)
 */
export const generateEndUserSession = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GenerateEndUserSession200>(
      {url: `/auth/end-user-session`, method: 'get', signal
    },
      options);
    }
  

export const getGenerateEndUserSessionQueryKey = () => [`/auth/end-user-session`];

    
export type GenerateEndUserSessionQueryResult = NonNullable<Awaited<ReturnType<typeof generateEndUserSession>>>
export type GenerateEndUserSessionQueryError = GenerateEndUserSession401 | GenerateEndUserSession500

export const useGenerateEndUserSession = <TData = Awaited<ReturnType<typeof generateEndUserSession>>, TError = GenerateEndUserSession401 | GenerateEndUserSession500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof generateEndUserSession>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGenerateEndUserSessionQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof generateEndUserSession>>> = ({ signal }) => generateEndUserSession(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof generateEndUserSession>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns a staff-user session tokens (only for lower stage, for back-end testing)
 */
export const generateStaffUserSession = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GenerateStaffUserSession200>(
      {url: `/auth/staff-user-session`, method: 'get', signal
    },
      options);
    }
  

export const getGenerateStaffUserSessionQueryKey = () => [`/auth/staff-user-session`];

    
export type GenerateStaffUserSessionQueryResult = NonNullable<Awaited<ReturnType<typeof generateStaffUserSession>>>
export type GenerateStaffUserSessionQueryError = GenerateStaffUserSession500

export const useGenerateStaffUserSession = <TData = Awaited<ReturnType<typeof generateStaffUserSession>>, TError = GenerateStaffUserSession500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof generateStaffUserSession>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGenerateStaffUserSessionQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof generateStaffUserSession>>> = ({ signal }) => generateStaffUserSession(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof generateStaffUserSession>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create StaffRoleMapping
 */
export const createStaffRoleMapping = (
    createStaffRoleMappingRequestDto: CreateStaffRoleMappingRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<CreateStaffRoleMapping200>(
      {url: `/create-staffRoleMapping`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createStaffRoleMappingRequestDto
    },
      options);
    }
  


    export type CreateStaffRoleMappingMutationResult = NonNullable<Awaited<ReturnType<typeof createStaffRoleMapping>>>
    export type CreateStaffRoleMappingMutationBody = CreateStaffRoleMappingRequestDto
    export type CreateStaffRoleMappingMutationError = CreateStaffRoleMapping500

    export const useCreateStaffRoleMapping = <TError = CreateStaffRoleMapping500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStaffRoleMapping>>, TError,{data: CreateStaffRoleMappingRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStaffRoleMapping>>, {data: CreateStaffRoleMappingRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  createStaffRoleMapping(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createStaffRoleMapping>>, TError, {data: CreateStaffRoleMappingRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Read StaffRoleMapping
 */
export const readStaffRoleMapping = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ReadStaffRoleMapping200>(
      {url: `/read-staffRoleMapping`, method: 'get', signal
    },
      options);
    }
  

export const getReadStaffRoleMappingQueryKey = () => [`/read-staffRoleMapping`];

    
export type ReadStaffRoleMappingQueryResult = NonNullable<Awaited<ReturnType<typeof readStaffRoleMapping>>>
export type ReadStaffRoleMappingQueryError = ReadStaffRoleMapping500

export const useReadStaffRoleMapping = <TData = Awaited<ReturnType<typeof readStaffRoleMapping>>, TError = ReadStaffRoleMapping500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readStaffRoleMapping>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadStaffRoleMappingQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof readStaffRoleMapping>>> = ({ signal }) => readStaffRoleMapping(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof readStaffRoleMapping>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Update StaffRoleMapping
 */
export const updateStaffRoleMapping = (
    updateStaffRoleMappingRequestDto: UpdateStaffRoleMappingRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<UpdateStaffRoleMapping200>(
      {url: `/update-staffRoleMapping`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateStaffRoleMappingRequestDto
    },
      options);
    }
  


    export type UpdateStaffRoleMappingMutationResult = NonNullable<Awaited<ReturnType<typeof updateStaffRoleMapping>>>
    export type UpdateStaffRoleMappingMutationBody = UpdateStaffRoleMappingRequestDto
    export type UpdateStaffRoleMappingMutationError = UpdateStaffRoleMapping500

    export const useUpdateStaffRoleMapping = <TError = UpdateStaffRoleMapping500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStaffRoleMapping>>, TError,{data: UpdateStaffRoleMappingRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStaffRoleMapping>>, {data: UpdateStaffRoleMappingRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  updateStaffRoleMapping(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateStaffRoleMapping>>, TError, {data: UpdateStaffRoleMappingRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Delete StaffRoleMapping
 */
export const deleteStaffRoleMapping = (
    deleteStaffRoleMappingRequestDto: DeleteStaffRoleMappingRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<DeleteStaffRoleMapping200>(
      {url: `/delete-staffRoleMapping`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deleteStaffRoleMappingRequestDto
    },
      options);
    }
  


    export type DeleteStaffRoleMappingMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStaffRoleMapping>>>
    export type DeleteStaffRoleMappingMutationBody = DeleteStaffRoleMappingRequestDto
    export type DeleteStaffRoleMappingMutationError = DeleteStaffRoleMapping500

    export const useDeleteStaffRoleMapping = <TError = DeleteStaffRoleMapping500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStaffRoleMapping>>, TError,{data: DeleteStaffRoleMappingRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStaffRoleMapping>>, {data: DeleteStaffRoleMappingRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  deleteStaffRoleMapping(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteStaffRoleMapping>>, TError, {data: DeleteStaffRoleMappingRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List StaffRoleMapping
 */
export const listStaffRoleMapping = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ListStaffRoleMappingResponseDto>(
      {url: `/list-staffRoleMapping`, method: 'get', signal
    },
      options);
    }
  

export const getListStaffRoleMappingQueryKey = () => [`/list-staffRoleMapping`];

    
export type ListStaffRoleMappingQueryResult = NonNullable<Awaited<ReturnType<typeof listStaffRoleMapping>>>
export type ListStaffRoleMappingQueryError = ListStaffRoleMapping500

export const useListStaffRoleMapping = <TData = Awaited<ReturnType<typeof listStaffRoleMapping>>, TError = ListStaffRoleMapping500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStaffRoleMapping>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStaffRoleMappingQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStaffRoleMapping>>> = ({ signal }) => listStaffRoleMapping(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listStaffRoleMapping>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns logged user id
 */
export const getCurrentStaffUser = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetCurrentStaffUser200>(
      {url: `/staff-users/me`, method: 'get', signal
    },
      options);
    }
  

export const getGetCurrentStaffUserQueryKey = () => [`/staff-users/me`];

    
export type GetCurrentStaffUserQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentStaffUser>>>
export type GetCurrentStaffUserQueryError = GetCurrentStaffUser500

export const useGetCurrentStaffUser = <TData = Awaited<ReturnType<typeof getCurrentStaffUser>>, TError = GetCurrentStaffUser500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCurrentStaffUser>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentStaffUserQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentStaffUser>>> = ({ signal }) => getCurrentStaffUser(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCurrentStaffUser>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Read staff-user
 */
export const readStaffUser = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ReadStaffUser200>(
      {url: `/staff-users/read`, method: 'get', signal
    },
      options);
    }
  

export const getReadStaffUserQueryKey = () => [`/staff-users/read`];

    
export type ReadStaffUserQueryResult = NonNullable<Awaited<ReturnType<typeof readStaffUser>>>
export type ReadStaffUserQueryError = ReadStaffUser500

export const useReadStaffUser = <TData = Awaited<ReturnType<typeof readStaffUser>>, TError = ReadStaffUser500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readStaffUser>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadStaffUserQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof readStaffUser>>> = ({ signal }) => readStaffUser(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof readStaffUser>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Delete staff-user
 */
export const deleteStaffUser = (
    deleteStaffUserRequestDto: DeleteStaffUserRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<DeleteStaffUser200>(
      {url: `/staff-users/delete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deleteStaffUserRequestDto
    },
      options);
    }
  


    export type DeleteStaffUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStaffUser>>>
    export type DeleteStaffUserMutationBody = DeleteStaffUserRequestDto
    export type DeleteStaffUserMutationError = DeleteStaffUser500

    export const useDeleteStaffUser = <TError = DeleteStaffUser500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStaffUser>>, TError,{data: DeleteStaffUserRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStaffUser>>, {data: DeleteStaffUserRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  deleteStaffUser(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteStaffUser>>, TError, {data: DeleteStaffUserRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List staff-users
 */
export const listStaffUsers = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetListStaffUserResponseDto>(
      {url: `/staff-users/list`, method: 'get', signal
    },
      options);
    }
  

export const getListStaffUsersQueryKey = () => [`/staff-users/list`];

    
export type ListStaffUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listStaffUsers>>>
export type ListStaffUsersQueryError = ListStaffUsers500

export const useListStaffUsers = <TData = Awaited<ReturnType<typeof listStaffUsers>>, TError = ListStaffUsers500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStaffUsers>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStaffUsersQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStaffUsers>>> = ({ signal }) => listStaffUsers(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listStaffUsers>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the initial state for traders
 */
export const getInitialTraderState = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetInitialTraderState200>(
      {url: `/staff-users/initial-state`, method: 'get', signal
    },
      options);
    }
  

export const getGetInitialTraderStateQueryKey = () => [`/staff-users/initial-state`];

    
export type GetInitialTraderStateQueryResult = NonNullable<Awaited<ReturnType<typeof getInitialTraderState>>>
export type GetInitialTraderStateQueryError = GetInitialTraderState500

export const useGetInitialTraderState = <TData = Awaited<ReturnType<typeof getInitialTraderState>>, TError = GetInitialTraderState500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInitialTraderState>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInitialTraderStateQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInitialTraderState>>> = ({ signal }) => getInitialTraderState(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getInitialTraderState>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create and Update pool colors list
 */
export const createPoolColors = (
    createPoolColorsRequestDto: CreatePoolColorsRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<CreatePoolColors200>(
      {url: `/staff-users/pool-colors`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPoolColorsRequestDto
    },
      options);
    }
  


    export type CreatePoolColorsMutationResult = NonNullable<Awaited<ReturnType<typeof createPoolColors>>>
    export type CreatePoolColorsMutationBody = CreatePoolColorsRequestDto
    export type CreatePoolColorsMutationError = CreatePoolColors500

    export const useCreatePoolColors = <TError = CreatePoolColors500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPoolColors>>, TError,{data: CreatePoolColorsRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPoolColors>>, {data: CreatePoolColorsRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  createPoolColors(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPoolColors>>, TError, {data: CreatePoolColorsRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List analytic firms
 */
export const listAnalyticFirms = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ListAnalyticFirms200>(
      {url: `/staff-users/analytic-firms`, method: 'get', signal
    },
      options);
    }
  

export const getListAnalyticFirmsQueryKey = () => [`/staff-users/analytic-firms`];

    
export type ListAnalyticFirmsQueryResult = NonNullable<Awaited<ReturnType<typeof listAnalyticFirms>>>
export type ListAnalyticFirmsQueryError = ListAnalyticFirms500

export const useListAnalyticFirms = <TData = Awaited<ReturnType<typeof listAnalyticFirms>>, TError = ListAnalyticFirms500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAnalyticFirms>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAnalyticFirmsQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAnalyticFirms>>> = ({ signal }) => listAnalyticFirms(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listAnalyticFirms>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create and Update analytic firms list
 */
export const createAnalyticFirms = (
    createAnalyticFirmsRequestDto: CreateAnalyticFirmsRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<CreateAnalyticFirms200>(
      {url: `/staff-users/analytic-firms`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAnalyticFirmsRequestDto
    },
      options);
    }
  


    export type CreateAnalyticFirmsMutationResult = NonNullable<Awaited<ReturnType<typeof createAnalyticFirms>>>
    export type CreateAnalyticFirmsMutationBody = CreateAnalyticFirmsRequestDto
    export type CreateAnalyticFirmsMutationError = CreateAnalyticFirms500

    export const useCreateAnalyticFirms = <TError = CreateAnalyticFirms500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAnalyticFirms>>, TError,{data: CreateAnalyticFirmsRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAnalyticFirms>>, {data: CreateAnalyticFirmsRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  createAnalyticFirms(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createAnalyticFirms>>, TError, {data: CreateAnalyticFirmsRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Read analytic firm
 */
export const readAnalyticFirm = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ReadAnalyticFirm200>(
      {url: `/staff-users/analytic-firm`, method: 'get', signal
    },
      options);
    }
  

export const getReadAnalyticFirmQueryKey = () => [`/staff-users/analytic-firm`];

    
export type ReadAnalyticFirmQueryResult = NonNullable<Awaited<ReturnType<typeof readAnalyticFirm>>>
export type ReadAnalyticFirmQueryError = ReadAnalyticFirm500

export const useReadAnalyticFirm = <TData = Awaited<ReturnType<typeof readAnalyticFirm>>, TError = ReadAnalyticFirm500>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAnalyticFirm>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAnalyticFirmQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof readAnalyticFirm>>> = ({ signal }) => readAnalyticFirm(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof readAnalyticFirm>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create analytic firm
 */
export const createAnalyticFirm = (
    createAnalyticFirmRequestDto: CreateAnalyticFirmRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<CreateAnalyticFirm200>(
      {url: `/staff-users/analytic-firm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAnalyticFirmRequestDto
    },
      options);
    }
  


    export type CreateAnalyticFirmMutationResult = NonNullable<Awaited<ReturnType<typeof createAnalyticFirm>>>
    export type CreateAnalyticFirmMutationBody = CreateAnalyticFirmRequestDto
    export type CreateAnalyticFirmMutationError = CreateAnalyticFirm400 | CreateAnalyticFirm500

    export const useCreateAnalyticFirm = <TError = CreateAnalyticFirm400 | CreateAnalyticFirm500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAnalyticFirm>>, TError,{data: CreateAnalyticFirmRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAnalyticFirm>>, {data: CreateAnalyticFirmRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  createAnalyticFirm(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createAnalyticFirm>>, TError, {data: CreateAnalyticFirmRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Update analytic firm list
 */
export const updateAnalyticFirm = (
    updateAnalyticFirmRequestDto: UpdateAnalyticFirmRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<UpdateAnalyticFirm200>(
      {url: `/staff-users/analytic-firm`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateAnalyticFirmRequestDto
    },
      options);
    }
  


    export type UpdateAnalyticFirmMutationResult = NonNullable<Awaited<ReturnType<typeof updateAnalyticFirm>>>
    export type UpdateAnalyticFirmMutationBody = UpdateAnalyticFirmRequestDto
    export type UpdateAnalyticFirmMutationError = UpdateAnalyticFirm500

    export const useUpdateAnalyticFirm = <TError = UpdateAnalyticFirm500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAnalyticFirm>>, TError,{data: UpdateAnalyticFirmRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAnalyticFirm>>, {data: UpdateAnalyticFirmRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  updateAnalyticFirm(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateAnalyticFirm>>, TError, {data: UpdateAnalyticFirmRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Attach analytic firm
 */
export const attachAnalyticFirm = (
    attachAnalyticFirmRequestDto: AttachAnalyticFirmRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<AttachAnalyticFirm200>(
      {url: `/staff-users/analytic-firm-attach-end-users`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: attachAnalyticFirmRequestDto
    },
      options);
    }
  


    export type AttachAnalyticFirmMutationResult = NonNullable<Awaited<ReturnType<typeof attachAnalyticFirm>>>
    export type AttachAnalyticFirmMutationBody = AttachAnalyticFirmRequestDto
    export type AttachAnalyticFirmMutationError = AttachAnalyticFirm500

    export const useAttachAnalyticFirm = <TError = AttachAnalyticFirm500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof attachAnalyticFirm>>, TError,{data: AttachAnalyticFirmRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof attachAnalyticFirm>>, {data: AttachAnalyticFirmRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  attachAnalyticFirm(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof attachAnalyticFirm>>, TError, {data: AttachAnalyticFirmRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Dissaciate end-user from analytic firm
 */
export const dissociateEndUser = (
    dissociateEndUserRequestDto: DissociateEndUserRequestDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<DissociateEndUser200>(
      {url: `/staff-users/dissociate-end-user`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: dissociateEndUserRequestDto
    },
      options);
    }
  


    export type DissociateEndUserMutationResult = NonNullable<Awaited<ReturnType<typeof dissociateEndUser>>>
    export type DissociateEndUserMutationBody = DissociateEndUserRequestDto
    export type DissociateEndUserMutationError = DissociateEndUser500

    export const useDissociateEndUser = <TError = DissociateEndUser500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dissociateEndUser>>, TError,{data: DissociateEndUserRequestDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dissociateEndUser>>, {data: DissociateEndUserRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  dissociateEndUser(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof dissociateEndUser>>, TError, {data: DissociateEndUserRequestDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Returns the staff-user trade history
 */
export const getStaffUserTradeHistory = (
    params?: GetStaffUserTradeHistoryParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<GetStaffUserTradeHistoryResponseDto>(
      {url: `/staff-users/trade-history`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetStaffUserTradeHistoryQueryKey = (params?: GetStaffUserTradeHistoryParams,) => [`/staff-users/trade-history`, ...(params ? [params]: [])];

    
export type GetStaffUserTradeHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getStaffUserTradeHistory>>>
export type GetStaffUserTradeHistoryQueryError = GetStaffUserTradeHistory500

export const useGetStaffUserTradeHistory = <TData = Awaited<ReturnType<typeof getStaffUserTradeHistory>>, TError = GetStaffUserTradeHistory500>(
 params?: GetStaffUserTradeHistoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStaffUserTradeHistory>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStaffUserTradeHistoryQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStaffUserTradeHistory>>> = ({ signal }) => getStaffUserTradeHistory(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getStaffUserTradeHistory>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Adds the trader to a trade request chat
 */
export const joinTradeRequestChat = (
    staffUserJoinTradeRequestConvoDto: StaffUserJoinTradeRequestConvoDto,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<JoinTradeRequestChat200>(
      {url: `/staff-users/join-trade-request-chat`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: staffUserJoinTradeRequestConvoDto
    },
      options);
    }
  


    export type JoinTradeRequestChatMutationResult = NonNullable<Awaited<ReturnType<typeof joinTradeRequestChat>>>
    export type JoinTradeRequestChatMutationBody = StaffUserJoinTradeRequestConvoDto
    export type JoinTradeRequestChatMutationError = JoinTradeRequestChat404 | JoinTradeRequestChat500

    export const useJoinTradeRequestChat = <TError = JoinTradeRequestChat404 | JoinTradeRequestChat500,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinTradeRequestChat>>, TError,{data: StaffUserJoinTradeRequestConvoDto}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof joinTradeRequestChat>>, {data: StaffUserJoinTradeRequestConvoDto}> = (props) => {
          const {data} = props ?? {};

          return  joinTradeRequestChat(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof joinTradeRequestChat>>, TError, {data: StaffUserJoinTradeRequestConvoDto}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List TradeRequestEvent
 */
export const listTradeRequestEvent = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      return customAxios<ListTradeRequestEventResponseDto[]>(
      {url: `/list-tradeRequestEvent`, method: 'get', signal
    },
      options);
    }
  

export const getListTradeRequestEventQueryKey = () => [`/list-tradeRequestEvent`];

    
export type ListTradeRequestEventQueryResult = NonNullable<Awaited<ReturnType<typeof listTradeRequestEvent>>>
export type ListTradeRequestEventQueryError = unknown

export const useListTradeRequestEvent = <TData = Awaited<ReturnType<typeof listTradeRequestEvent>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTradeRequestEvent>>, TError, TData>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTradeRequestEventQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTradeRequestEvent>>> = ({ signal }) => listTradeRequestEvent(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listTradeRequestEvent>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


