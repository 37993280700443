import type { ErrorDto, TradeRequestDto } from '@utility-nyc/react-query-sdk';

import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import type { AppStateStatus } from 'react-native';
import { AppState } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import { Client } from '@twilio/conversations';
import { useAuthenticationStatus } from '@utility-nyc/react-auth-core';
import { useAuthStore } from '@utility-nyc/react-auth-storage';
import { useShallow } from 'zustand/react/shallow';

import { Toast } from '@shared/utils';
import { useRefreshTwilioAccessToken } from '@south-street-app/hooks';
import {
  MessageType,
  useBackendError,
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import {
  TradeRequestDtoStatus,
  useEndUserAccessToken,
  useGetInitialEndUserState,
} from '@utility-nyc/react-query-sdk';

type TwilioWrapperProps = {
  children: ReactNode;
};

const TRADE_COMPLETED_STATUSES: TradeRequestDtoStatus[] = [
  TradeRequestDtoStatus.EXECUTED,
  TradeRequestDtoStatus.CANCELED,
  TradeRequestDtoStatus.OFFER_REJECTED,
];

const TwilioWrapper = ({ children }: TwilioWrapperProps) => {
  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  const navigation = useNavigation();
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const handleLogout = useAuthStore((state) => state.handleLogout);
  const setBackendError = useBackendError((state) => state.setBackendErrorData);

  const {
    chatConversation,
    getTradeRequestId,
    setConversation,
    setTradeProposalId,
    setCounterProposal,
    setReceivedQuotedPrice,
    setTradeTimeout,
    setProposal,
    setChatConversation,
    setChatAuthor,
    setChatTrader,
    setEndUserId,
    setTradeRequestId,
    setIsNewQuotedPrice,
    setIsTwilioConnected,
  } = useMobileTradeStore(
    useShallow((state) => ({
      setIsTwilioConnected: state.setIsTwilioConnected,
      chatConversation: state.chatConversation,
      getTradeRequestId: state.getTradeRequestId,
      setConversation: state.setConversation,
      setTradeProposalId: state.setTradeProposalId,
      setCounterProposal: state.setCounterProposal,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      setTradeTimeout: state.setTradeTimeout,
      setProposal: state.setProposal,
      setChatConversation: state.setChatConversation,
      setChatAuthor: state.setChatAuthor,
      setChatTrader: state.setChatTrader,
      setEndUserId: state.setEndUserId,
      setTradeRequestId: state.setTradeRequestId,
      setIsNewQuotedPrice: state.setIsNewQuotedPrice,
    })),
  );
  const { data } = useGetInitialEndUserState({
    query: { enabled: !!refreshToken },
  });

  const {
    data: accessTokens,
    refetch: refetchAccessTokens,
    error: accessTokensError,
  } = useEndUserAccessToken({ query: { enabled: !!refreshToken } });

  const authStatus = useAuthenticationStatus();

  const updateConversation = useCallback(
    async (twilioClient: Client) => {
      if (chatConversation && chatConversation.uniqueName) {
        const newChatConverstation =
          await twilioClient.getConversationByUniqueName(
            chatConversation.uniqueName,
          );

        setChatConversation(newChatConverstation);
      }
    },
    [chatConversation, setChatConversation],
  );

  const [client, setClient] = useState<Client | undefined>();

  const updateClient = useCallback(async () => {
    if (!accessTokens?.data.twilioAccessToken) {
      return undefined;
    }

    if (client) {
      const newClient = await client.updateToken(
        accessTokens.data.twilioAccessToken,
      );

      updateConversation(newClient);

      return newClient;
    }

    const newClient = new Client(accessTokens.data.twilioAccessToken);

    setClient(newClient);

    return newClient;
  }, [accessTokens?.data.twilioAccessToken, client, updateConversation]);

  useRefreshTwilioAccessToken();

  const clearTwilioAccessTokenIfLoggedOut = useCallback(() => {
    if (authStatus === 'logged out' && accessTokens?.data.twilioAccessToken) {
      useAuthStore.getState().twilioAccessToken = undefined;
    }
  }, [authStatus, accessTokens?.data.twilioAccessToken]);

  const offerRejectedCheck = useCallback(
    async (messageData: TradeRequestDto, twilioClient: Client) => {
      if (
        messageData.status === TradeRequestDtoStatus.OFFER_REJECTED &&
        messageData.chatTopicId
      ) {
        const chatConverstation =
          await twilioClient.getConversationByUniqueName(
            messageData.chatTopicId,
          );

        setChatConversation(chatConverstation);
      }
    },
    [setChatConversation],
  );
  const tradeRequestTimedoutCheck = useCallback(
    async (messageData: TradeRequestDto, twilioClient: Client) => {
      if (
        messageData.status === TradeRequestDtoStatus.TIMED_OUT &&
        messageData.chatTopicId
      ) {
        const chatConverstation =
          await twilioClient.getConversationByUniqueName(
            messageData.chatTopicId,
          );

        setChatConversation(chatConverstation);
      }
    },
    [setChatConversation],
  );

  const tradeRequestCanceledCheck = useCallback(
    async (
      messageData: TradeRequestDto,
      messageType: MessageType,
      twilioClient: Client,
    ) => {
      if (
        messageType === MessageType.TradeRequestCanceled &&
        getTradeRequestId() === messageData.id &&
        messageData.chatTopicId
      ) {
        setChatTrader(messageData.assignedTo?.firstName ?? 'Trader');

        setChatAuthor(`END_USER:${messageData.createdBy.id}`);

        openBottomSheet({
          type: 'tradeTimeout',
          subType: 'canceled',
        });

        const chatConverstation =
          await twilioClient.getConversationByUniqueName(
            messageData.chatTopicId,
          );

        setChatConversation(chatConverstation);
      }
    },
    [
      getTradeRequestId,
      setChatTrader,
      setChatAuthor,
      openBottomSheet,
      setChatConversation,
    ],
  );

  const tradeRequestStateChangedCheck = useCallback(
    async (messageData: TradeRequestDto, type: MessageType) => {
      if (
        type === MessageType.TradeRequestStateChanged &&
        (messageData.status === 'OFFER_SENT' ||
          messageData.status === 'OFFER_RESENT') &&
        messageData.counterproposal?.id
      ) {
        if (messageData.status === 'OFFER_RESENT') {
          setIsNewQuotedPrice(true);
        }

        setTradeProposalId(messageData.counterproposal.id);

        setEndUserId(messageData.createdBy.id);

        setCounterProposal(messageData.counterproposal);

        setReceivedQuotedPrice(true);

        setChatAuthor(`END_USER:${messageData.createdBy.id}`);

        setChatTrader(messageData.assignedTo?.firstName || 'Trader');
      }
    },
    [
      setChatAuthor,
      setChatTrader,
      setCounterProposal,
      setEndUserId,
      setIsNewQuotedPrice,
      setReceivedQuotedPrice,
      setTradeProposalId,
    ],
  );

  const tradeRequestCreatedCheck = useCallback(
    async (messageData: TradeRequestDto, type: MessageType) => {
      if (type === MessageType.TradeRequestCreated) {
        setProposal(messageData.proposal);

        setTradeRequestId(messageData.id);
      }
    },
    [setProposal, setTradeRequestId],
  );

  const tradeRequestExecuted = useCallback(
    async (messageData: TradeRequestDto, type: MessageType) => {
      if (
        type === MessageType.TradeRequestExecuted &&
        messageData.status === 'EXECUTED'
      ) {
        openBottomSheet({
          type: 'tradeConfirmation',
        });
      }
    },
    [openBottomSheet],
  );

  const onMessageAdded = useCallback(
    async (body: string | null, twilioClient: Client) => {
      const {
        type,
        data: messageData,
        success,
        error,
      } = JSON.parse(body || '') as
        | {
            type: MessageType;
            data: TradeRequestDto;
            error: undefined;
            success: true;
          }
        | {
            type: undefined;
            data: undefined;
            error: ErrorDto;
            success: false | undefined;
          };

      if (success) {
        await tradeRequestStateChangedCheck(messageData, type);

        await tradeRequestExecuted(messageData, type);

        await tradeRequestCreatedCheck(messageData, type);

        await tradeRequestCanceledCheck(messageData, type, twilioClient);

        await offerRejectedCheck(messageData, twilioClient);

        await tradeRequestTimedoutCheck(messageData, twilioClient);

        console.log('messageAdded', type, messageData);

        Toast.showDebugToast({
          visibilityTime: 5000,
          text1: `Twilio: ${type}`,
          text2: `status: ${messageData.status} id: ${messageData.id} ${messageData.createdBy.firstName} ${messageData.createdBy.lastName}`,
        });
      } else {
        if (success === false) {
          Sentry.captureException(error, {
            data: { message: 'request creation failed' },
          });

          setBackendError({ error: String(error) });

          openBottomSheet({
            type: 'backendError',
          });

          Toast.showDebugToast({
            visibilityTime: 5000,

            text1: `Twilio message error: ${error.message}`,
          });
        }
      }
    },
    [
      tradeRequestStateChangedCheck,
      tradeRequestExecuted,
      tradeRequestCreatedCheck,
      tradeRequestCanceledCheck,
      offerRejectedCheck,
      tradeRequestTimedoutCheck,
      setBackendError,
      openBottomSheet,
    ],
  );

  const tradeInProgressCheck = useCallback(() => {
    const state = navigation.getState();
    const name = state && state.index ? state.routes[state.index].name : '';

    if (
      data?.data.tradeRequest?.status &&
      !TRADE_COMPLETED_STATUSES.includes(data.data.tradeRequest.status) &&
      name === 'HomeScreen'
    ) {
      openBottomSheet({
        type: 'tradeInProgress',
      });
    }
  }, [data, navigation, openBottomSheet]);

  const twilioInitialSetup = useCallback(() => {
    if (client && refreshToken) {
      client.on('initialized', async () => {
        if (data?.data.liveUpdatesTopicId) {
          const conversation = await client.getConversationByUniqueName(
            data.data.liveUpdatesTopicId || '',
          );

          Toast.showDebugToast({
            text1: 'Twilio initialized',
          });

          setIsTwilioConnected(true);

          setConversation(conversation);

          setTradeTimeout(data.data.systemSettings.timeoutInSeconds);
        }
      });
    }
  }, [
    client,
    setIsTwilioConnected,
    data?.data.liveUpdatesTopicId,
    data?.data.systemSettings.timeoutInSeconds,
    refreshToken,
    setConversation,
    setTradeTimeout,
  ]);

  const twilioInitFailedHandler = useCallback(() => {
    if (client && refreshToken) {
      client.on('initFailed', (error) => {
        console.error('Twilio initialization failed', error);

        Toast.showDebugToast({
          visibilityTime: 5000,
          text1: 'Twilio initialization failed',
          text2: error.error?.message,
        });

        openBottomSheet({
          type: 'tradeInProgress',
        });
      });
    }
  }, [client, refreshToken, openBottomSheet]);

  const twilioTokenEpired = useCallback(async () => {
    if (client && refreshToken) {
      client.on('tokenExpired', async () => {
        await refetchAccessTokens();
      });
    }
  }, [client, refetchAccessTokens, refreshToken]);

  const twilioTokenAboutToExpire = useCallback(async () => {
    if (client && refreshToken) {
      client.on('tokenAboutToExpire', async () => {
        await refetchAccessTokens();
      });
    }
  }, [client, refetchAccessTokens, refreshToken]);

  const twilioMessageAddedHandler = useCallback(() => {
    if (client) {
      client.on('messageAdded', async (message) => {
        await onMessageAdded(message.body, client);
      });
    }
  }, [client, onMessageAdded]);

  const resetOnUnmount = useCallback(() => {
    client?.removeAllListeners();

    client?.shutdown();
  }, [client]);

  const [appState, setAppState] = useState(AppState.currentState);

  const handleAppStateChange = useCallback(
    async (nextAppState: AppStateStatus) => {
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        if (client?.connectionState !== 'connected') {
          setIsTwilioConnected(false);

          await updateClient();
        }
      }

      if (client?.connectionState === 'connected') {
        setIsTwilioConnected(true);
      }

      setAppState(nextAppState);
    },

    [appState, updateClient, client, setIsTwilioConnected],
  );

  useEffect(() => {
    if (client?.connectionState !== 'connected') {
      setIsTwilioConnected(false);
    }

    if (client?.connectionState === 'connected') {
      setIsTwilioConnected(true);
    }
  }, [client, setIsTwilioConnected]);

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );

    return () => {
      subscription.remove();
    };
  }, [handleAppStateChange]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (accessTokensError?.response?.status === 401) {
      handleLogout();
    }
  }, [accessTokensError, handleLogout]);

  useEffect(() => {
    updateClient();
  }, [updateClient, accessTokens?.data.twilioAccessToken]);

  useEffect(clearTwilioAccessTokenIfLoggedOut, [
    clearTwilioAccessTokenIfLoggedOut,
  ]);

  useEffect(tradeInProgressCheck, [tradeInProgressCheck]);

  useEffect(() => {
    twilioInitialSetup();
  }, [twilioInitialSetup]);

  useEffect(() => {
    twilioInitFailedHandler();
  }, [twilioInitFailedHandler]);

  useEffect(() => {
    twilioTokenEpired();
  }, [twilioTokenEpired]);

  useEffect(() => {
    twilioTokenAboutToExpire();
  }, [twilioTokenAboutToExpire]);

  useEffect(() => {
    twilioMessageAddedHandler();
  }, [twilioMessageAddedHandler]);

  useEffect(() => resetOnUnmount, [resetOnUnmount]);

  return <>{children}</>;
};

export { TwilioWrapper };
