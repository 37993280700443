import type { Conversation } from '@twilio/conversations';
import type {
  CreateTradeRequestDto,
  TradeRequestDtoType,
  TradeRequestProposalDto,
  TradeRequestShareDto,
} from '@utility-nyc/react-query-sdk';
import type { IMessage } from 'react-native-gifted-chat';

import * as Sentry from '@sentry/react-native';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const SENTRY_TAG_TYPE = 'store';
const SENTRY_TAG_CONTEXT = 'useMobileTradeStore';

type FirmEndUsers = {
  id: string;
  name: string;
};

type MobileTradeStoreState = {
  conversation: Conversation | null;
  chatConversation: Conversation | null;
  tradeType: TradeRequestDtoType;
  tradeProposalId: string | null;
  proposal: TradeRequestProposalDto;
  persistantMessages: IMessage[];
  counterProposal: TradeRequestProposalDto;
  receivedQuotedPrice: boolean;
  tradeTimeout: number | undefined;
  chatAuthor: string;
  chatTrader: string;
  endUserId: string;
  tradeRequestId: string;
  isNewQuotedPrice: boolean;
  isTwilioConnected: boolean;
  firmEndUsers: FirmEndUsers[];
  shares: TradeRequestShareDto[];
  firmProposal: TradeRequestProposalDto | undefined;
  deleteEndUserById: (id: string) => void;
  clearFirmEndUsers: () => void;
  setShares: (shares: TradeRequestShareDto[]) => void;
  setFirmEndUsers: (firmEndUsers: FirmEndUsers[]) => void;
  setPersistantMessages: (persistantMessages: IMessage[]) => void;
  clearPersistantMessages: () => void;
  setIsTwilioConnected: (isTwilioConnected: boolean) => void;
  getTradeRequestId: () => string;
  setConversation: (conversation: Conversation) => void;
  setChatConversation: (conversation: Conversation) => void;
  setTradeType: (tradeType: TradeRequestDtoType) => void;
  setTradeProposalId: (tradeProposalId: string) => void;
  setProposal: (proposal: TradeRequestProposalDto) => void;
  setFirmProposal: (proposals: TradeRequestProposalDto) => void;
  setCounterProposal: (counterProposal: TradeRequestProposalDto) => void;
  setReceivedQuotedPrice: (receivedQuotedPrice: boolean) => void;
  setIsNewQuotedPrice: (isNewQuotedPrice: boolean) => void;
  setTradeTimeout: (tradeTimeout: number | undefined) => void;
  createTradeRequest: (trade: CreateTradeRequestDto) => void;
  acceptCounterproposal: () => void;
  rejectCounterproposal: () => void;
  cancelTrade: () => void;
  sendChatMessage: (message: string) => void;
  setChatAuthor: (author: string) => void;
  setChatTrader: (trader: string) => void;
  setEndUserId: (endUserId: string) => void;
  setTradeRequestId: (tradeRequestId: string) => void;
};

enum MessageType {
  CreateTradeRequest = 'create-trade-request',
  AcceptCounterproposal = 'accept-counterproposal',
  RejectCounterproposal = 'reject-counterproposal',
  TradeRequestCreated = 'trade-request-created',
  TradeRequestStateChanged = 'trade-request-state-changed',
  TradeRequestExecuted = 'trade-request-executed',
  TradeRequestCanceled = 'trade-request-canceled',
  CancelTradeRequest = 'cancel-trade-request',
}

const useMobileTradeStore = createWithEqualityFn<MobileTradeStoreState>(
  (set, get) => ({
    persistantMessages: [],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    conversation: get()?.conversation,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    chatConversation: get()?.chatConversation,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    tradeType: get()?.tradeType,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    tradeProposalId: get()?.tradeProposalId,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    proposal: get()?.proposal,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    counterProposal: get()?.counterProposal,
    tradeTimeout: 180,
    receivedQuotedPrice: false,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    chatAuthor: get()?.chatAuthor,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    chatTrader: get()?.chatTrader,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    endUserId: get()?.endUserId,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    tradeRequestId: get()?.tradeRequestId,
    isNewQuotedPrice: false,
    firmEndUsers: [],
    firmProposal: undefined,
    shares: [],
    isTwilioConnected: false,

    setIsTwilioConnected: (isTwilioConnected) => set({ isTwilioConnected }),
    setShares: (shares) => set({ shares }),
    clearFirmEndUsers: () => set({ firmEndUsers: [] }),
    setFirmEndUsers: (endUsers) => set({ firmEndUsers: endUsers }),
    setFirmProposal: (proposals) => set({ firmProposal: proposals }),
    deleteEndUserById: (id) =>
      set({
        firmEndUsers: get().firmEndUsers.filter((user) => user.id !== id),
      }),
    setPersistantMessages: (persistantMessages) => {
      set({ persistantMessages });
    },
    clearPersistantMessages: () => set({ persistantMessages: [] }),
    getTradeRequestId: () => get().tradeRequestId,
    setConversation: (conversation) => set({ conversation }),
    setChatConversation: (conversation) =>
      set({ chatConversation: conversation }),
    setTradeType: (tradeType) => set({ tradeType }),
    setTradeProposalId: (tradeProposalId) => set({ tradeProposalId }),
    setProposal: (proposal) => set({ proposal }),
    setCounterProposal: (counterProposal) => set({ counterProposal }),
    setReceivedQuotedPrice: (receivedQuotedPrice) =>
      set({ receivedQuotedPrice }),
    setIsNewQuotedPrice: (isNewQuotedPrice) => set({ isNewQuotedPrice }),
    setTradeTimeout: (tradeTimeout) => set({ tradeTimeout }),
    setChatAuthor: (author) => set({ chatAuthor: author }),
    setChatTrader: (trader) => set({ chatTrader: trader }),
    createTradeRequest: async (trade) => {
      const conversation = get().conversation;

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.CreateTradeRequest,
          data: trade,
        }),
      );
    },
    acceptCounterproposal: () => {
      const conversation = get().conversation;
      const proposalId = get().tradeProposalId;

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.AcceptCounterproposal,
          data: {
            tradeProposalId: proposalId,
          },
        }),
      );
    },
    rejectCounterproposal: () => {
      const conversation = get().conversation;
      const proposalId = get().tradeProposalId;

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.RejectCounterproposal,
          data: {
            tradeProposalId: proposalId,
          },
        }),
      );
    },
    cancelTrade: () => {
      const conversation = get().conversation;
      const tradeRequestId = get().tradeRequestId;
      const endUserId = get().endUserId;
      const clearFirmEndUsers = get().clearFirmEndUsers;

      clearFirmEndUsers();

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.CancelTradeRequest,
          data: {
            tradeRequestId,
            endUserId,
          },
        }),
      );

      set({ tradeRequestId: '' });
    },
    sendChatMessage: (message) => {
      const convo = get().chatConversation;

      if (!convo) {
        return;
      }

      try {
        convo.sendMessage(message);
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            type: SENTRY_TAG_TYPE,
            context: SENTRY_TAG_CONTEXT,
            method: 'sendChatMessage',
          },
        });

        throw error;
      }
    },
    setEndUserId: (endUserId) => {
      set({ endUserId });
    },
    setTradeRequestId: (tradeRequestId) => {
      set({ tradeRequestId });
    },
  }),
  shallow,
);

export { useMobileTradeStore, MessageType };
export type { FirmEndUsers };
