import React, { useCallback } from 'react';

import { FlatList, StyleSheet } from 'react-native';

import * as Sentry from '@sentry/react-native';
import { YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { capitalizeFirstLetter } from '@shared/utils';
import { HeadingM, LabelXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { SlideButton } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import {
  useGetCurrentEndUser,
  type TradeComponentInputDto,
} from '@utility-nyc/react-query-sdk';

import { DisplayTradeInfo } from './DisplayTradeInfo';

const SENTRY_TAG_TYPE = 'screen';
const SENTRY_TAG_CONTEXT = 'FirmMultiplePreviewTradeOrder';

const styles = StyleSheet.create({
  flatlist: { gap: 16 },
});

const FirmMultiplePreviewTradeOrder = () => {
  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );
  const { data } = useGetCurrentEndUser();
  const {
    createTradeRequest,
    setReceivedQuotedPrice,
    proposal,
    tradeType,
    firmEndUsers,
    isTwilioConnected,
    shares,
  } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      isTwilioConnected: state.isTwilioConnected,
      createTradeRequest: state.createTradeRequest,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      tradeType: state.tradeType,
      firmEndUsers: state.firmEndUsers,
      shares: state.shares,
    })),
  );

  const handleOnCompleted = useCallback(async () => {
    const components: TradeComponentInputDto[] = proposal.components.map(
      (component) => ({
        productId: component.product.id,
        amount: component.amount,
        type: component.type,
      }),
    );

    try {
      await createTradeRequest({
        components,
        type: tradeType,
        shares,
        endUserId: data?.data.id || '',
      });

      setReceivedQuotedPrice(false);

      openBottomSheet({
        type: 'requestTrade',
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: SENTRY_TAG_TYPE,
          context: SENTRY_TAG_CONTEXT,
          method: 'handleOnCompleted',
        },
      });

      openBottomSheet({
        type: 'backendError',
      });
    }
  }, [
    createTradeRequest,
    proposal.components,
    openBottomSheet,
    setReceivedQuotedPrice,
    data,
    shares,
    tradeType,
  ]);

  return (
    <YStack space={'$4'} flex={1} justifyContent={'space-between'}>
      <YStack flex={1} space={'$7'}>
        <HeadingM paddingTop={'$1'} fontWeight={'$3'}>
          {capitalizeFirstLetter(tradeType)}
        </HeadingM>
        <FlatList
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
          data={firmEndUsers}
          contentContainerStyle={styles.flatlist}
          renderItem={({ item }) => (
            <YStack
              space={'$2'}
              padding={'$4'}
              borderColor={'$mono200'}
              borderWidth={'$px'}
              borderRadius={'$l'}
            >
              <LabelXL paddingTop={'$1'} fontWeight={'$3'}>
                {item.name}
              </LabelXL>
              <YStack height={'$px'} backgroundColor={'$mono200'} />
              <DisplayTradeInfo
                useSeparators={false}
                order={{
                  ...proposal.components[0],
                  amount:
                    shares.find(
                      (share) =>
                        share.associatedId === item.id &&
                        share.type === proposal.components[0].type,
                    )?.amount ?? 0,
                }}
                isAlternativeLabel
              />
              <YStack height={'$px'} backgroundColor={'$mono200'} />
              <DisplayTradeInfo
                useSeparators={false}
                order={{
                  ...proposal.components[1],
                  amount:
                    shares.find(
                      (share) =>
                        share.associatedId === item.id &&
                        share.type === proposal.components[1].type,
                    )?.amount ?? 0,
                }}
                isAlternativeLabel
              />
            </YStack>
          )}
        />
      </YStack>
      <SlideButton
        isLoading={!isTwilioConnected}
        label={
          isTwilioConnected
            ? en_US.submitTradeRequest
            : en_US.connectingToTradeNetwork
        }
        onCompleted={handleOnCompleted}
      />
    </YStack>
  );
};

export { FirmMultiplePreviewTradeOrder };
