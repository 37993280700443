import * as Sentry from '@sentry/react-native';

const setupSentry = () => {
  Sentry.init({
    // eslint-disable-next-line dot-notation
    dsn: process.env['SENTRY_DSN'],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,

    // Set the replaySampleRate to 0.1 to capture 10% of sessions.
    replaysSessionSampleRate: 0.1,

    // Set the replayonErrorSampleRate to 1.0 to capture 100% of errors sessions.
    replaysOnErrorSampleRate: 1.0,

    integrations: [Sentry.mobileReplayIntegration()],
  });
};

export { setupSentry };
